const { default: apiClient } = require("../Client");

const fetchAffiliates = (params) => apiClient.get('affiliates', params);

const fetchAffiliate = (id) => apiClient.get('affiliates/' + id);

const fetchAffiliateMerchants = (id) => apiClient.get('affiliates/' + id + '/merchants', {limit: 30});

const fetchAffiliateStatusChanges = (id) => apiClient.get('affiliates/' + id + '/status-changes', {limit: 30});

const changeStatus = (affiliate_id, payload) => apiClient.post(`affiliates/${affiliate_id}/change-status`, payload);

const sendAffiliateCode = (affiliate_id, payload) => apiClient.post(`affiliates/${affiliate_id}/send-code`, payload);

const verifyCode = (affiliate_id, params) => apiClient.get(`affiliates/${affiliate_id}/verify-code`, params);

const storeAffiliate = (payload) => apiClient.post('affiliates', payload);

const updateAffiliate = (id, payload) => apiClient.put('affiliates/' + id, payload);


export default {
    fetchAffiliates, storeAffiliate, changeStatus, fetchAffiliateMerchants, fetchAffiliateStatusChanges, sendAffiliateCode, updateAffiliate,
    verifyCode, fetchAffiliate
}