import React from 'react';
import { Avatar, Card, Table, Tabs } from 'antd';
import colors from '../../../../configs/colors';
import StatusIndicator from '../../../../components/StatusIndicator/StatusIndicator';
import userService from '../../../../services/user.service';
import dayjs from 'dayjs';

const { TabPane } = Tabs;

const AffiliateTransactionReportTabs = ({affiliate, merchants= [], statuses = [], statusLoading = false, merchantLoading = false}) => {

    const merchantColumns = [
        {
            title: 'Logo',
            dataIndex: 'logo_url',
            key: 'logo',
            render: (url) => <Avatar src={url} size={96} shape="square" />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'client_type',
            key: 'client_type',
        },
        {
            title: 'Location',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <StatusIndicator status={text} />
        }
    ];

      const statusColumn = [
        {
            title: 'Previous Status',
            dataIndex: 'previous_status',
            key: 'previous_status',
            render: (_status) => <StatusIndicator status={_status} />
        },
        {
            title: 'Current Status',
            dataIndex: 'current_status',
            key: 'current_status',
            render: (_status) => <StatusIndicator status={_status} />
        },
        {
            title: 'Changed By',
            key: 'changed_by',
            render: (_status) => userService.fullname(_status?.user?.person)
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date) => dayjs(date).format('DD MMM YYYY hh:mma')
        },
      ];
    return (
        <Card>
            {!affiliate ?  <span style={{color: colors.gray}}>Select Affiliate to see Merchants.</span> :
             <Tabs>
                <TabPane tab="Merchants" key="1">
                    <Table dataSource={merchants} loading={merchantLoading} columns={merchantColumns} />
                </TabPane>
                <TabPane tab="Status Changes" key="2">
                    <Table dataSource={statuses} loading={statusLoading} columns={statusColumn} />
                </TabPane>
            </Tabs>
            }

           
        </Card>
    );
}


export default AffiliateTransactionReportTabs;