import React from 'react';

import Logo from '../../../assets/logo.png'
import './AuthHeader.less'

const AuthHeader = (props) => {
    return (
        <div className='AuthHeader'>
            <img src={Logo} className="Logo" />
        </div>
    );
}


export default AuthHeader;