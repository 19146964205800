import notify from './notification.service';



const handleFailed = (response) => {
    if(response.problem == 'NETWORK_ERROR') {
        notify('error', 'Network Error', 'Please Check Internet');
    } else {
        notify('error', 'Error', response.data?.error_msg);
    }
}

export default { handleFailed}