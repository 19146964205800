import { Button, Card, Col, Row, Select, Space, Spin } from 'antd';
import React, {useRef, useState, useCallback} from 'react';
import debounce from 'lodash/debounce';
import MerchantClient from '../../../api/merchant/MerchantClient';
import notify from '../../../services/notification.service';
import utilsService from '../../../services/utils.service';

const { Option } = Select;
const MerchantQrCode = (props) => {

    const [loading, setLoading] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [selectedValue, setValue] = useState({merchant1: null, merchant2: null, merchant3: null, merchant4: null});
    const [generateLoading, setGenerateLoading] = useState(false);
    
    const debounceCall = useCallback(debounce((query) => handleFetchMerchants(query), 1000), []);

    const handleFetchMerchants = async(query) => {
        setLoading(true); 
        const response = await MerchantClient.fetchMerchants({search_string: query});
        setLoading(false);
        if(response.ok) {
            const res = response.data.data?.merchants || [];
            const _merhants = res.map((r) => {
                return {value: r.code+"*"+r.name+"*"+r.id, text: r.name}
            } );
            setMerchants(_merhants);
        }
    }

    const fetchMerchants = (query) => {
        if(query) {
            debounceCall(query);
        } else {
            setMerchants([]);
        }
    }

    const handleChange =(key,value) => {
            const pre = {...selectedValue, [key]: value};
            setValue(pre);
    }

    const onGenerateQrCode = async () => {

        if (!selectedValue.merchant1) {
            notify('warning', 'QR', 'Select Merchant First!');
            return ;
        }
        if (generateLoading) return ;

        setGenerateLoading(true);
   

        const splitter = selectedValue.merchant1.split('*');
        const merchantName = splitter[1] || "";
        const merchantCode = splitter[0] || "";
        if (merchantName) {
            utilsService.onGenerateQrCode(merchantName, merchantCode);
            setTimeout(() => {
                    setGenerateLoading(false);
            }, 5000);
        }
      
    }


    return (
        <Row gutter={[16,16]}>
            <Col sm={24} md={16}>
                <Card title="Generate QR">

                    <Row gutter={[16,16]}>
                        <Col xs={24} sm={24} md={24}>

                            <Select
                                showSearch
                                value={selectedValue.merchant1}
                                max={4}
                                placeholder="Select merchant"
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                allowClear
                                onSearch={fetchMerchants}
                                onChange={(values) => handleChange('merchant1', values)}
                                style={{ width: '100%' }}
                                notFoundContent={loading ? <Spin size="small" /> : null}
                            >
                                {merchants.map(d => (<Option key={d.value + '_merchants'} value={d.value}>{d.text}</Option>))}
                            </Select>

                        </Col>

                        <Col sm={24} md={24}>
                            <Space>
                                <Button type="primary" onClick={onGenerateQrCode} loading={generateLoading}>Generate Qr</Button>
                            </Space>
                        </Col>
                    </Row>
                </Card>
            </Col>

        </Row>
        
    );
}


export default MerchantQrCode;