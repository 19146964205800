import React, { useEffect, useState } from 'react';
import { Tabs, Card, Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import awsService from '../../../../services/aws.service';
import onboardingClient from '../../../../api/onboardingimages/onboardingClient';

const {TabPane}  = Tabs;

const  getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const OnboardingImages = ({images = [], type='sign_in', onImageRemove = (id) => { }}) => {

    const [signInImages, setSignInImages] = useState({
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList: [
        ],
      });

      const handleCancel = () => setSignInImages({...signInImages, previewVisible: false});

      const handlePreview = async file => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
    
        setSignInImages({...signInImages, ...{
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
          }})
      };

  
    const  handleChange = ({ fileList, file }) => {
      
        
    };

    const handleRemove = (file) => {
        if(file.id) {
            onboardingClient.removeImage(file.id).then((res) => {
                if(res.ok) {
                    onImageRemove(file.id);
                }
             })
            
        } 

        // setSignInImages({...signInImages, fileList: [...signInImages.fileList.filter((f) => f.uid !== file.uid)]})

        return false;
    }

    const beforeUpload = file => {
            getBase64(file).then((imageUrl) => {
                const local = {uid: file.uid, name: file.name, status: 'uploading', url: imageUrl, percent: 40};
                const listb4 = [...signInImages.fileList, local];
                setSignInImages({...signInImages, fileList: listb4});
                awsService.sendToBucket(file)
                .then((response) => {
                    const listAfter = [...listb4.map((m) => {
                        if(m.uid === local.uid) {
                            return {...m, url: response.Location, percent: 90};
                        }
                        return m;
                    })];
                    setSignInImages({...signInImages, fileList: listAfter});

                    onboardingClient.addImage({url: response.Location, ad_type: type}).then((res) => {
                        if(res.ok) {
                            if(res.data.data && res.data.data.image) {
                                const uploadList = [...listAfter.map((m) => {
                                    if(m.uid === local.uid) {
                                        return {...m, id: res.data.data.image.id, status: 'done', percent: 100};
                                    }
                                    return m;
                                })];
                                setSignInImages({...signInImages, fileList: uploadList});
                                return false;
                            }
                        } 

                        const _uploadList = [...listAfter.map((m) => {
                            if(m.uid === local.uid) {
                                return {...m, status: 'error'};
                            }
                            return m;
                        })];
                        setSignInImages({...signInImages, fileList: _uploadList});
                    });
                })
                .catch(err => {
                    
                    console.log('Error:', err);
                }) 
            }); 
               
            

            return false;
        }

    useEffect(() => {

        const _fileList = images.filter((image) => image.ad_type === type).map((i) => ({id: i.id, uid: i.id, url: i.url}));
        const _prev = signInImages.fileList.filter((p) => !p.id);
        setSignInImages({...signInImages, fileList: [..._fileList, ..._prev]});
    }, [images]);

    const uploadButton = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );
    return (
        <>
            <Upload
                
                listType="picture-card"
                fileList={signInImages.fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                onRemove={handleRemove}
                >
                    {uploadButton}
            </Upload>
            <Modal
            visible={signInImages.previewVisible}
            title={signInImages.previewTitle}
            footer={null}
            onCancel={handleCancel}
            >
            <img alt="example" style={{ width: '100%' }} src={signInImages.previewImage} />
            </Modal>
        </>
    );
}


export default OnboardingImages;