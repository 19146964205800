import { Card, Col, Row, Tabs } from 'antd';
import React from 'react';
import FranchiseMerchants from './FranchiseMerchants/FranchiseMerchants';
import NewMerchants from './NewMerchants/NewMerchants';
import SingleMerchants from './SingleMerchants/SingleMerchants';

const { TabPane } = Tabs;

const MerchantTabs = ({newmerchants, singleMerchants, franchiseMerchants, onGenerateMerchantQr, onFranchiseExpanded=(id) => { }, loading, onMerchantSelected}) => {
    return (
        <Row gutter={[16,16]}>
            <Col xs={24} sm={24} md={24}>
                <Card bordered={false}>
                    <Tabs>
                        <TabPane tab="NEW MERCHANTS" key="s_0_1">
                            <NewMerchants loading={loading} merchants={newmerchants} onMerchantSelected={onMerchantSelected} onGenerateMerchantQr={onGenerateMerchantQr} />
                        </TabPane>
                    </Tabs>
                </Card>
            </Col>

            <Col xs={24} sm={24} md={24}>
                <Card bordered={false}>
                    <Tabs>
                        <TabPane tab="SINGLE MERCHANTS" key="s_1">
                            <SingleMerchants merchants={singleMerchants} loading={loading} onMerchantSelected={onMerchantSelected} onGenerateMerchantQr={onGenerateMerchantQr} />
                        </TabPane>

                        <TabPane tab="FRANCHISE MERCHANTS" key="s_2">
                            <FranchiseMerchants merchants={franchiseMerchants} loading={loading} onExpanded={onFranchiseExpanded}
                                onMerchantSelected={onMerchantSelected} onGenerateMerchantQr={onGenerateMerchantQr} />
                        </TabPane>
                    </Tabs>
                </Card>
            </Col>
        </Row>
    );
}


export default MerchantTabs;