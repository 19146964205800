import "./App.less";
import AppLayout from "./components/layouts/app/AppLayout";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./pages/app/dashboard/Dashboard";
import Login from "./pages/auth/login/Login";
import { PrivateRoute } from "./services/route.service";
import { useEffect, useState } from "react";
import AuthContext from "./hooks/AuthContext";
import AuthService from "./services/auth.service";
import Merchant from "./pages/app/merchants/Merchant";
import Affiliate from "./pages/app/Affiliates/Affiliate";
import Report from "./pages/app/Reports/Report";
import Promotion from "./pages/app/Promotions/Promotion";
import AccountManagement from "./pages/app/AccountManagement/AccountManagement";
import NewsLetter from "./pages/app/news-letter/NewsLetter";
import ResetPasswordLink from "./pages/auth/reset-password/ResetPasswordLink";
import ResetPasswordWithToken from "./pages/auth/reset-password-with-token/ResetPasswordWithToken";
import MerchantQrCode from "./pages/app/qr-code/MerchantQrCode";
import ResourceCenter from './pages/app/ResourceCenter/ResourceCenter';
import FundRequest from "./pages/app/FundRequest/FundRequest";

function App() {
  const [isLogin, setLogin] = useState(false);
  const [user, setUser] = useState();
  useEffect(() => {
    setLogin(() => AuthService.isLogin());
  }, []);

  return (
    <AuthContext.Provider value={{isLogin, setLogin, user, setUser}}>
      <Router>
        <AppLayout>
          <Switch>
            <PrivateRoute exact path="/" component={Dashboard} />

            <PrivateRoute exact path="/merchants" component={Merchant} />

            <PrivateRoute exact path="/qr-codes" component={MerchantQrCode} />

            <PrivateRoute exact path="/affiliates" component={Affiliate} />

            <PrivateRoute exact path="/fund-request" component={FundRequest} />

            <PrivateRoute exact path="/reports" component={Report} />

            <PrivateRoute exact path="/give-aways" component={Promotion} />

            <PrivateRoute exact path="/news-letter" component={NewsLetter} />

            <PrivateRoute exact path="/account-managements" component={AccountManagement} />

            <PrivateRoute exact path="/resource-center" component={ResourceCenter} />

            <Route exact path="/login" component={Login} />

            <Route exact path="/reset-password" component={ResetPasswordLink} />

            <Route exact path="/reset-password-with-token" component={ResetPasswordWithToken} />
          </Switch>
        </AppLayout>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
