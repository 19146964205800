import { Tag } from 'antd';
import React from 'react';
import { SyncOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const statusColors = {
    under_review: 'processing',
    approved: 'green',
    suspended: 'red'
}

const statusIcons = {
    under_review: <SyncOutlined spin />,
    approved: <CheckCircleOutlined />,
    suspended: <CloseCircleOutlined />
}
const StatusIndicator = ({status = '', onClick}) => {
    const color = statusColors[status] ? statusColors[status]: statusColors.under_review;
    const statusText = status  ?  status.replace('_',  ' ') : 'Not Set.';
    const icon = statusIcons[status] ? statusIcons[status] : <ExclamationCircleOutlined />
    return (
        <Tag icon={icon} color={color} style={{cursor: "pointer"}} onClick={onClick}>{statusText}</Tag>
    );
}


export default StatusIndicator;