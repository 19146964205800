import React from 'react';
import { Tabs, Card, Row, Col } from 'antd';
import OnboardingImages from './OnboardingImages';

const OnboardingImageTabs = ({images, onImageRemove = (id) => { }}) => {
    return (
        <Row gutter={[16,16]}>
            <Col sm={24} md={12}>
                <Card title="Sign In Images">
                    <OnboardingImages onImageRemove={onImageRemove} images={images} type="sign_in" />
                </Card>
            </Col>
            <Col sm={24} md={12}>
                <Card title="Sign Up Images">
                    <OnboardingImages onImageRemove={onImageRemove} images={images} type="sign_up" />
                </Card>
            </Col>

            <Col sm={24} md={12}>
                <Card title="Mobile Home">
                    <OnboardingImages onImageRemove={onImageRemove} images={images} type="mobile_main" />
                </Card>
            </Col>

            <Col sm={24} md={12}>
                <Card title="Mobile Promo">
                    <OnboardingImages onImageRemove={onImageRemove} images={images} type="mobile_promo" />
                </Card>
            </Col>
        </Row>
    );
}


export default OnboardingImageTabs;