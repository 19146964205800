import { Avatar, Card, Col, Input, Row, Select , Space, Tabs, Modal, Form, Button } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import userService from '../../../../services/user.service';
import _Avatar from '../../../../assets/avatar.png';
import './AffiliateTabs.css';
import colors from '../../../../configs/colors';
import AffiliateClient from '../../../../api/affiliate/AffiliateClient';
import notify from '../../../../services/notification.service';
import Uploader from '../../../../components/uploader/uploader';
import FilterPane from '../../../../components/Filter/FilterPane';

const { TabPane } = Tabs;
const { Option } = Select;

const inactiveStyle = {padding: '10px', borderRadius: '5px', marginBottom: '10px'};
const activeStyle = {...inactiveStyle, backgroundColor: colors.purple_blue, color: colors.white, }

const AffiliateTabs = ({affiliates = [], onAffiliateSelected =() => { }, 
                        onShowAffiliateModal, onToggleAffiliateModal, onAffiliateAdded, onSearch = (values) => { },
                    searchLoading = false}) => {

    const [selectedIndex, setIndex] = useState();
    const [affiliateToUpdate, setAffliateToUpdate] = useState();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [profileUrl, setProfileUrl] = useState(null);

    

    const handleAffiliateForm =async (values) => {
        if(!values.username || values.username.length < 3) {
            notify('warning', 'Username', 'Please enter valid username');
            return ;
        }
        
        if(!values.password || values.password.length < 9) {
            notify('warning', 'Password', 'Password length is too short, it should contain more than 9 characters');
            return ;
        }

        if(profileUrl) {
            values.profile_pic_url = profileUrl;
        }


        setLoading(true);
        const response = await AffiliateClient.storeAffiliate({...values, confirmed_password: values.password});
        setLoading(false);

        if(response.ok) {
            notify('success', 'Affiliate', 'Affiliate Added Successfully');
            handleModalClose();
            if(onAffiliateAdded) {
                onAffiliateAdded();
            }
            setProfileUrl(null);

        } else {
            if(response.problem == 'NETWORK_ERROR') {
                notify('error', 'Network Error', 'Please Check Internet');
            } else {
                console.log(response.data);
                notify('error', 'Error', response.data?.error_msg);
            }
        }
    }

    const handleUpdateAffiliate =async (values) => {

        if(profileUrl) {
            values.profile_pic_url = profileUrl;
        }

        setLoading(true);
        const response = await AffiliateClient.updateAffiliate(affiliateToUpdate?.id, {...values});
        setLoading(false);

        if(response.ok) {
            notify('success', 'Affiliate', 'Affiliate Updated Successfully');
            handleModalClose();
            if(onAffiliateAdded) {
                onAffiliateAdded();
                onAffiliateSelected(response.data?.data?.affiliate);
            }
            setProfileUrl(null);

        } else {
            if(response.problem == 'NETWORK_ERROR') {
                notify('error', 'Network Error', 'Please Check Internet');
            } else {
                notify('error', 'Error', response.data?.error_msg);
            }
        }
    }

    const onSelectAffiliateToUpdate = (affiliate) => {
        const _af = {id: affiliate.id, phone_number: affiliate.phone_number, first_name: affiliate.person.first_name, surname: affiliate.person.surname, email: affiliate.email, tin_number: affiliate.tin_number, gender: affiliate.person.gender_name}
        form.setFieldsValue(_af);
        setAffliateToUpdate(_af);
    }

    const handleModalClose =() => {
        onToggleAffiliateModal();
        clearForm();
    }

    const clearForm = () => {
        form.resetFields();
        setAffliateToUpdate(null);
        form.setFieldsValue(null);

    }

      useEffect(() => {
          if(onShowAffiliateModal) {
              form.resetFields();
              setProfileUrl(null);
          }

      }, [onShowAffiliateModal]);

    return (
        <Card bordered={false} >
            <Tabs>
            <TabPane tab="Affiliates" key="1">

                <FilterPane loading={searchLoading} onSearch={onSearch} searchPlaceholder="Search Affiliates" />
                {
                    affiliates.map((affiliate, index) => (
                        <div className="borders" key={affiliate.id + '_' + index} style={selectedIndex==index ? activeStyle : inactiveStyle}>
                            <Row gutter={16}>
                                <Col xs={24} sm={24} md={8}>
                                    <Space>
                                        <Avatar src={affiliate?.person?.profile_pic_url} size={48} />
                                        <span style={{fontSize: '9px', fontWeight: 600}}>{userService.fullname(affiliate?.person)}</span>
                                    </Space>
                                </Col>

                                <Col xs={24} sm={24} md={8} style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-around'}}>
                                    <Space>
                                        <span style={{fontSize: '9px', fontWeight: 600}}>{affiliate?.region?.name}</span>
                                    </Space>
                                </Col>

                                <Col xs={24} sm={24} md={8} style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-around'}}>
                                    <div>
                                        <span style={{fontSize: '9px', fontWeight: 600}}>ID: {affiliate.id}</span>
                                    </div>

                                    <div>
                                        <Space>
                                            <EyeOutlined style={{cursor: 'pointer'}} onClick={() => {onAffiliateSelected(affiliate); setIndex(index)}} />
                                            <EditOutlined style={{cursor: 'pointer'}} onClick={() => {onSelectAffiliateToUpdate(affiliate); onToggleAffiliateModal()}} />
                                        </Space>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) )
                }

            </TabPane>
        </Tabs>

        <Modal title={!affiliateToUpdate ? "Add Affiliate" : "Update Affiliate"} footer={null} visible={onShowAffiliateModal} onCancel={handleModalClose}>
            <Form
                layout="vertical"
                form={form}
                initialValues={affiliateToUpdate}
                onFinish={!affiliateToUpdate ? handleAffiliateForm : handleUpdateAffiliate}
            >
                <Row>
                    <Col xs={24} sm={24} md={8}>
                        <Uploader onClear={onShowAffiliateModal} uploadImage={(url) => setProfileUrl(url)} />
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                        <Form.Item hasFeedback name="first_name" rules={[{required: true, message: 'Please Enter First Name'}]}>
                            <Input placeholder="First Name" />
                        </Form.Item>

                        <Form.Item hasFeedback name="surname" rules={[{required: true, message: 'Please Enter Surname'}]}>
                            <Input placeholder="Surname" />
                        </Form.Item>

                        {/* <Form.Item rules={[{required: true, message: 'Please Enter Region'}]} name="region">
                            <Input placeholder="Region" />
                        </Form.Item> */}

                        <Form.Item hasFeedback name="tin_number" rules={[{required: affiliateToUpdate ? false:true, message: 'Please Enter TIN'}]}>
                            <Input placeholder="TIN" />
                        </Form.Item>

                        <Form.Item hasFeedback rules={[{required: affiliateToUpdate ? false:true, message: 'Please Enter Email'}]} name="email">
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item name="telephone" >
                            <Input placeholder="Phone Number" />
                        </Form.Item>
                    

                        {!affiliateToUpdate && <>

                            <Form.Item  name="username">
                            <Input placeholder="Username" />
                        </Form.Item>

                            <Form.Item name="password">
                            <Input.Password placeholder="Temporary Password" />
                        </Form.Item>
                        </>}

                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                        </Form.Item>

                       
                    </Col>
                </Row>
            </Form>
        </Modal>
        </Card>
    );
}


export default AffiliateTabs;