import { Button, Card, Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import AuthService from "../../../services/auth.service";
import notify from "../../../services/notification.service";
import AuthClient from '../../../api/auth/AuthClient'

import "./Login.less";
import tokenService from "../../../services/token.service";


const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ forname: "", password: "" });
  const fornameRef = React.createRef();
  const passwordRef = React.createRef();

  const { login, isLogin } = useAuth();
  const history = useHistory();

  const handleLogin =async () => {
      if(!formData.forname){
          notify('warn', 'Login', 'EmployeeID is Required');
          fornameRef.current.focus();
          return ;
      }

      if(!formData.password) {
        notify('warn', 'Login', 'Password is Required');
        passwordRef.current.focus();
        return ;
      }
    setLoading(true);
    const response = await AuthClient.login(formData);
    setLoading(false);

    if(response.ok) {
      if(response.data.success) {
        login(response.data.data);
      }
    } else {
      if(response.problem == 'NETWORK_ERROR') {
        notify('error', 'Network Error', 'Please Check your internet and try again');
        return ;
      } else {
        notify('error', 'Login Failed', 'Wrong username/password');
        return ;
      }
      
      
    }
  };

  const handleFormChange = (name, event) => {
      const previous = formData;
      const current = {...previous, [name]: event.target.value};
      setFormData(() => current);
  }

  const resetPassword = () => {
    history.push('reset-password');
  }

  useEffect(() => {
    if (isLogin) {
    //   history.push("/");
    const loc = AuthService.getCurrentMenu();
    history.push(loc);

    }
  }, [isLogin]);
  return (
    <div className="Login">
      <Row style={{ justifyContent: "center", flex: 1 }}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <h6 className="AdminTitle">Admin</h6>
          <h6 className="AdminTitle">Dashboard</h6>
        </Col>

        <Col sm={24} md={8} style={{ flex: 1, justifyContent: "center" }}>
          <Card className="Form-Card">
            <Input placeholder="EmployeeID" ref={fornameRef} style={{ marginBottom: "20px" }} onChange={(e) => handleFormChange('forname', e)} />

            <Input.Password placeholder="Password" ref={passwordRef} style={{ marginBottom: "20px" }} onChange={(e) => handleFormChange('password', e)} />

            <a className="login-form-forgot" style={{marginBottom: '16px', float: 'right', color: 'skyblue'}} href="#" onClick={resetPassword}>Forgot password?</a>

            <Button
              type="primary"
              block
              shape="round"
              onClick={handleLogin}
              loading={loading}
            >
              LOG IN
            </Button>
          </Card>
        </Col>

        <Col
          span={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <p className="Note">
            <b>Note:</b> Dont share your employee credentials with anyone.
          </p>
          <p className="Note">
            You are responsible for any decision taking on this dashboard as you
            log in.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
