
import { Form, Input, Button, Card, Col, Row, Select, Space, Typography, Divider, Popconfirm } from 'antd';
import React, {useState, useEffect} from 'react';
import UniversalUploader from '../../../components/uploader/UniversalUploader';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';
import DocumentAsset from '../../../assets/document.png';
import ResourceCenterClient from '../../../api/resource-center/ResourceCenterClient';
import networkService from "../../../services/network.service";
import colors from '../../../configs/colors';
import FilterPane from '../../../components/Filter/FilterPane';
import notify from '../../../services/notification.service';
const {Option} = Select;
const { Title } = Typography;


// {id: 1, title: 'Hello Worl', url: 'https://www.youtube.com/watch?v=krB0enBeSiE&ab_channel=LexFridman', type: 'video'},
// {id: 2, title: 'PDF Nice one', url: 'https://primeegiftfiles.s3.us-east-2.amazonaws.com/prime-images/4MpWp_The-GRE-Analytical-Writing-Templates.pdf', type: 'document'}

const ResourceCenter = (props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [resource, setResource] = useState(false);
    const [resources, setResources] = useState([])
    const [resourceType, setResourceType] = useState('video');

    const onHandleSubmit = async (values) => {
        setLoading(true);
        const response =  resource ? await ResourceCenterClient.updateResource(resource.id, values) : await ResourceCenterClient.storeResource(values);
        setLoading(false);
        if(response.ok) {
            const _resource_ = response.data.data?.resource || null;
            if(_resource_) {
                if(resource) {
                    const _resouces = resources.map((_r) => {
                        if(_r.id == resource.id) return _resource_;
                        return _r;
                    });
                    setResources(_resouces);
                } else {
                    const _resouces = [...resources];
                    _resouces.unshift(_resource_);
                    setResources(_resouces);
                }

                handleFormReset();
                notify('success', 'Resouce Center', 'Success.')
            }

            setResource(null);

            return ;
        }

        networkService.handleFailed(response);
    }

    const onDeleteResource = async (id) => {
        setLoading(true);
        const response = await ResourceCenterClient.removeResource(id);
        setLoading(false);
        if(response.ok) {
            const _resouces = resources.filter((r) => r.id !== id);
            setResources(_resouces);
            notify('success', 'Resource Center', 'Resource Removed.');
            return ;
        }
        networkService.handleFailed(response);
    }
 
    const onResourceSelected = (_resource) => {
        setResource(_resource);
        form.setFieldsValue(_resource);
        setResourceType(_resource.type);
    }

    const fetchResources = async (params= { limit: 30}) => {
        setLoading(true);
        const response = await ResourceCenterClient.fetchResources(params);
        setLoading(false);

        if(response.ok) {
            setResources(response.data.data.resources);
        }
    }

    const onTypeChange = (value) => {
        setResourceType(value);
    }

    const generatePreview = (resource) => {
        switch(resource.type) {
            case 'video':
                return <VideoPlayer url= {resource.url} />;
            case 'document':
                return <>
                    <a href={resource.url} target="_new"><img src={DocumentAsset} /></a>
                </>;
            default:
                return <></>;
        }
    }

    const handleFormReset = () => {
        setResource(null);
        form.resetFields();
        setResourceType('video');
    }

    useEffect(() => {
        fetchResources();
    }, []);

    return (
        <div className='ResourceCenter'>
            <Row gutter={[16,16]}>
                <Col sm={24} md={8}>
                    <Card bordered={false} title="Add/Update Resource">
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={{ title: null, content: null, type: 'video' }}
                            onFinish={onHandleSubmit}
                        >
                            <Form.Item label="Title" name="title">
                                <Input  />
                            </Form.Item>

                            <Form.Item label="Type" name="type">
                                <Select onChange={onTypeChange}>
                                    <Option value="video">video</Option>
                                    <Option value="document">document</Option>
                                </Select>
                            </Form.Item>

                            <UniversalUploader  onFileUploaded= {(url) => form.setFieldsValue({url: url})} />
                            {/* {resourceType !== 'video' && <UniversalUploader  onFileUploaded= {(url) => form.setFieldsValue({content: url})} />} */}

                            
                            <Form.Item label="Content" name="url" style={{marginTop: '16px'}}>
                                <Input placeholder='url' />
                            </Form.Item>
                         
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading}>{resource ? 'UPDATE' : 'SAVE'}</Button>
                                <Button type="link"  htmlType="reset" style={{color: colors.danger}} onClick={handleFormReset}>Reset</Button>
                            </Form.Item>
                        </Form>
                   
                    </Card>
                </Col>

                <Col sm={24} md={16}>
                    <Card bordered={false} title="Resources">
                        <Row gutter={[16,16]}>
                            <Col md={24}>
                                <FilterPane loading={loading} showStatus={false} showType={true} onSearch={(params) => fetchResources(params)} />
                            </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                        {resources.map((_resource, index) => (
                            <Col md={24} key={_resource.id + '_resources_' + index}>
                              {generatePreview(_resource)}
                              <Title level={2}>{_resource.title}</Title>
                              <Space>
                                  <Button type="primary" loading={loading} onClick={() => onResourceSelected(_resource)}>Edit</Button>
                                  <Popconfirm
                                    title="Are you sure to delete this resource?"
                                    onConfirm={() =>onDeleteResource(_resource.id)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="text" loading={loading}>Delete</Button>
                                </Popconfirm>
                                  
                              </Space>
                              <Divider />
                            </Col>))}

                            <Col>
                            {resources.length == 0 && <span style={{color: colors.gray}}>No Resource Uploaded yet.</span>}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}


export default ResourceCenter;