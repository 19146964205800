import { Button, Card, Col, Input, Row } from "antd";
import React, {useState } from "react";
import { useHistory } from "react-router-dom";
import notify from "../../../services/notification.service";
import AuthClient from '../../../api/auth/AuthClient'

import "./ResetPasswordLink.less";
import colors from "../../../configs/colors";
import urls from "../../../configs/urls";


const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ user_id: "", password_reset_url: urls.frontend + 'reset-password-with-token' });
  const fornameRef = React.createRef();


  const history = useHistory();

  const handleLogin =async () => {
      if(!formData.user_id){
          notify('warn', 'Reset Password', 'Email | Username is Required');
          fornameRef.current.focus();
          return ;
      }
    setLoading(true);
    const response = await AuthClient.requestPasswordReset(formData);
    setLoading(false);

    if(response.ok) {
      if(response.data.success) {
        notify('success', 'Reset Password', response.data?.data?.message);
        goToLogin();
      }
    } else {
      if(response.problem == 'NETWORK_ERROR') {
        notify('error', 'Network Error', 'Please Check your internet and try again');
        return ;
      } else {
        notify('error', 'Reset Password', response.data?.error_msg);
        return ;
      }
      
      
    }
  };

  const handleFormChange = (name, event) => {
      const previous = formData;
      const current = {...previous, [name]: event.target.value};
      setFormData(() => current);
  }

  const goToLogin = () => {
      history.replace('login');
  }


  return (
    <div className="Login">
      <Row style={{ justifyContent: "center", flex: 1 }}>
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <h6 className="AdminTitle">Admin</h6>
          <h6 className="AdminTitle">Dashboard</h6>
        </Col>

        <Col sm={24} md={16} style={{ flex: 1, justifyContent: "center" }}>
          <Card className="Form-Card">
            <Input placeholder="Email | Username | Phone number" ref={fornameRef} style={{ marginBottom: "20px" }} onChange={(e) => handleFormChange('user_id', e)} />
            <Button
              type="primary"
              block
              shape="round"
              style={{marginBottom: '20px'}}
              onClick={handleLogin}
              loading={loading}
            >
              Reset Password
            </Button>

            <div style={{textAlign: 'center'}}>
                <a style={{color: colors.gray, fontStyle: 'italic'}} href="#" onClick={goToLogin}>Not Now, Take me Back.</a>
            </div>
          </Card>
        </Col>

        <Col
          span={24}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <p className="Note">
          </p>
          <p className="Note">
           
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
