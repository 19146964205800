import qrcode from 'qrcode';
import { jsPDF } from "jspdf";

const randomString = (length = 3) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 const capitalName = (name) => {
    return name ?  name.toUpperCase() : name;
 }

 const onGenerateQrCode = async (name, code) => {
   const url = await qrcode.toDataURL(code);
   const doc = new jsPDF({format: 'a6'});
   const text = capitalName(name);
   const pageWidth = doc.internal.pageSize.getWidth();
   const canvasWidth = 50;
   const canvasHeight = 50;

   const marginX = (pageWidth - canvasWidth) / 2;

   doc.addImage(url, "PNG", marginX-1, 63,canvasWidth,canvasHeight);
   doc.setFontSize(11);
   doc.text(text, pageWidth/2, 112, {align: 'center'});
   doc.save(text+ "-qr.pdf", {returnPromise: true });
 }



 export default {randomString, capitalName, onGenerateQrCode}