const fullname = (person) => {
    if(!person) return '';

    const middle = person.other_names || '';
    const first  = person.first_name  || '';
    const lastName = person.surname || '';

    return first + ' ' + middle + lastName;
}

export default {
    fullname
}