import { Button, Table, Space } from 'antd';
import React from 'react';
import { EyeOutlined, QrcodeOutlined } from '@ant-design/icons';
import StatusIndicator from '../../../../../components/StatusIndicator/StatusIndicator';

const FranchiseMerchants = ({merchants, loading, onExpanded = (id) => { }, onMerchantSelected = (merchant) => { }, onGenerateMerchantQr = (merchant) => { }}) => {

    const onExpand = (exp, record) => {
        if(exp) {
            onExpanded(record.id)
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'f_name',
        },
        {
            title: 'Location',
            dataIndex: 'address',
            key: 'f_address',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'f_status',
            render: (text) => <StatusIndicator status={text} />
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'f_actions',
            render: (text) => (
                <Space>
                    <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => onMerchantSelected(text)}></Button>
                    <Button type="primary" shape="circle" icon={<QrcodeOutlined />} onClick={() => onGenerateMerchantQr(text)}></Button>

                </Space>
            )
        }
    ]

    return (
        <Table columns={columns} dataSource={merchants} loading={loading} onExpand={onExpand} />
    );
}


export default FranchiseMerchants;