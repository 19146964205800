import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const styles = {
    dateTime: {fontSize: '14px', fontWeight: 600}
}
const Timer = (props) => {
    const [currentTime, setCurrentTime] = useState(dayjs());


    const tick = () => {
        setCurrentTime(() => dayjs());
    }

    useEffect(() => {
        const interval = setInterval(() => {
            tick();
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <div className='Timer' style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexDirection: 'column'}}>
            <span >Date: <span style={styles.dateTime}>{currentTime.format('D MMMM, YYYY')}</span></span>
            <span>Time: <span style={styles.dateTime}>{currentTime.format('hh:mm a')}</span></span>
        </div>
    );
}


export default Timer;