const { default: apiClient } = require("../Client");

const addImage = (payload) => apiClient.post('marketing/images', payload);

const fetchImages = () => apiClient.get('marketing/images');

const removeImage = (id) => apiClient.delete(`marketing/images/${id}`);


export default {
    addImage, fetchImages,removeImage
}