import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import "./MainMenu.less";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { MenuOutlined } from '@ant-design/icons';
import authService from "../../services/auth.service";

const MainMenu = (props) => {
  const [selectedMenu, setSelectedMenu] = useState("/");
  const history = useHistory();

  const handleMenuChange = (e) => {
        setSelectedMenu(e.key);
        history.replace(e.key);
        AuthService.setCurrentMenu(e.key);
  }

  useEffect(() => {
      const key = AuthService.getCurrentMenu();
      setSelectedMenu(() => key);
  }, []);

  return (
    <div className="MainMenu">
      <Menu
        onClick={handleMenuChange}
        selectedKeys={[selectedMenu]}
        mode="horizontal"
        overflowedIndicator={
            <>
                <MenuOutlined />
            </>
        }
      >
        <Menu.Item key="/">Home</Menu.Item>

        {authService.hasPermission('merchants') && <Menu.Item key="merchants">Merchants</Menu.Item>}

        {authService.hasPermission('affiliates') && <Menu.Item key="affiliates">Affiliates</Menu.Item>}

        {/* <Menu.Item key="fund-request">Fund Requests</Menu.Item> */}

        {authService.hasPermission('merchants') && <Menu.Item key="qr-codes">Qr Code</Menu.Item>}

        {authService.hasPermission('reports') && <Menu.Item key="reports">Reports</Menu.Item>}

        {authService.hasPermission('give_back_campaigns') && <Menu.Item key="give-aways">Give Away</Menu.Item>}

        {authService.hasPermission('newsletters') && <Menu.Item key="news-letter">News Letter</Menu.Item>}
        
        {authService.hasPermission('resource_centre') && <Menu.Item key="resource-center">Resource Center</Menu.Item>}
        
        {authService.hasAny(['marketing', 'activity_logs', 'users']) && <Menu.Item key="account-managements">Account Management</Menu.Item>}

      </Menu>
    </div>
  );
};

export default MainMenu;
