import { Avatar, Card, Col, Divider, Row, Space, Switch, Tag } from 'antd';
import React from 'react';
import { EditOutlined } from '@ant-design/icons';

import colors from '../../../../configs/colors'
import _Avatar from '../../../../assets/affliliate.png'
import userService from '../../../../services/user.service';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import maskService from '../../../../services/mask.service';
import StatusIndicator from '../../../../components/StatusIndicator/StatusIndicator';

dayjs.extend(relativeTime);

const labelStyle = {
    color: '#8798AA',
    fontSize: '8px'
}

const regularText = {
    fontSize: '15px'
}

const AffliliateDetail = ({affiliate, onChangeStatusClicked}) => {
    return (
        <Card>
            

            {!affiliate && <span style={{color: colors.gray}}>No Affiliate Selected.</span>}

            {affiliate && (
                <div>
                    <Row>
                        <Col xs={24} sm={24} md={8} style={{alignItems: 'center', display: 'flex'}}>
                            <Avatar src={affiliate?.person?.profile_pic_url} size={92} />
                        </Col>

                        <Col xs={24} sm={24} md={16}>
                            <div style={{textAlign: 'right'}}>
                                <span style={{opacity: 0.5, fontSize: '20px'}}>Account ID: </span> <span style={{ fontSize: '20px'}}>{affiliate.id}</span>
                                <br />
                                <Space>
                                    <StatusIndicator status={affiliate.status} onClick={onChangeStatusClicked} />
                                    
                                </Space>
                            </div>

                            <div>
                                <span style={{fontSize: '18px', fontWeight: 600}}>{userService.fullname(affiliate?.person)}</span>
                                <br/>
                                <span style={{fontSize: '15px', color: colors.success, fontWeight: 400}}>{affiliate?.username} | {affiliate?.reference}</span>
                            </div>

                            <div style={{textAlign: "right"}}>
                                {/* <EditOutlined style={{color: colors.purple_blue}} /> */}
                            </div>
                            
                        </Col>
                    </Row>

                    <Divider />

                    <Row gutter={[16,16]}>
                        <Col xs={24} sm={24} md={12}>
                            <span style={labelStyle}>YEARS</span><br />
                            <span style={regularText}>{dayjs(affiliate.created_at).fromNow()}</span>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                            <span style={labelStyle}>TIN NUMBER</span><br />
                            <span>{affiliate?.person?.tin_number}</span>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                            <span style={labelStyle}>EMAIL</span><br />
                            <span>{affiliate.email}</span>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                            <span style={labelStyle}>TEL</span><br />
                            <span>{affiliate.phone_number}</span>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                            <span style={labelStyle}>NUMBER OF CLIENTS (This Month)</span><br />
                            <span style={regularText}>{affiliate?.clients_added_this_month || 'N/A'}</span>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                            <span style={labelStyle}>LAST WITHDRAWAL {dayjs(affiliate.last_withdrawal?.date).format('DD.MMM.YYYY')}</span><br />
                            <span style={regularText}>Amount: {affiliate?.last_withdrawal?.amount || '0'} </span>
                        </Col>

                        <Col xs={24} sm={24} md={12}>
                            <span style={labelStyle}>TOTAL COMMISSION RECEIVED</span><br />
                            <span style={regularText}>{affiliate?.total_commission_till_date}</span>
                        </Col>

                    </Row>

                    <Row>
                       

                       
                    </Row>

                    <Divider />
                </div>
                
            )}

        </Card>
    );
}


export default AffliliateDetail;