// https://api.primeegiftcard.com/prime.sika/v1/
// https://api.primeegiftcard.com/acc.egift.card/v1/

const isLocal = false;
const frontend = isLocal ? 'http://localhost:3000/' : 'admin.primeegiftcard.com';

export default {
    isLocal,
    frontend,
    base: 'https://api.primeegiftcard.com/prime.admin/v1/',
    sikaBase: 'https://api.primeegiftcard.com/prime.sika/v1/'
}