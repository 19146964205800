import { Card, Tabs } from 'antd';
import React from 'react';
import NewGiveAwayTable from './NewGiveAwayTable/NewGiveAwayTable';
import PassedGiveAwayTable from './PassedGiveAwayTable/PassedGiveAwayTable';

const { TabPane } = Tabs;

const GiveAwayRightTabs = ({campaigns, changeCampaignStatus = (c) => { }, onSearch= (values) => { }, onEditCampaign, loading}) => {
    return (
        <div className='grid grid-cols-1'>
            <Card bordered={false}>
                <Tabs>
                    <TabPane tab="New Give-Aways" key="1">
                        <NewGiveAwayTable changeCampaignStatus={changeCampaignStatus} onSearch={onSearch} onEditCampaign={onEditCampaign} campaigns={campaigns} loading={loading} />
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    );
}


export default GiveAwayRightTabs;