import React, {Component} from 'react'
import { Redirect, Route} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';


export const PrivateRoute= ({ component: Component, ...rest }) => {
    const {isLogin} = useAuth();

    return (
      <Route {...rest}
        render={({ location }) => {
         return isLogin ?  <Component {...rest} /> : 
            <Redirect to={{pathname: "/login",state: { from: location }}} />
        }       
        }
      />
    );
  }