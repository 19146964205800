import { Button, Table, Space } from "antd";
import React, { useEffect } from "react";
import { EyeOutlined, QrcodeOutlined } from "@ant-design/icons";
import StatusIndicator from "../../../../../components/StatusIndicator/StatusIndicator";

const NewMerchants = ({
  merchants,
  onMerchantSelected = (merchant) => {},
  onGenerateMerchantQr = (merchant) => {},
  loading,
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "m_name",
    },
    {
      title: "Location",
      dataIndex: "address",
      key: "m_address",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "m_status",
      render: (text) => <StatusIndicator status={text} />,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "m_actions",
      render: (text) => (
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={<EyeOutlined />}
            onClick={() => onMerchantSelected(text)}
          ></Button>
          <Button
            type="primary"
            shape="circle"
            icon={<QrcodeOutlined />}
            onClick={() => onGenerateMerchantQr(text)}
          ></Button>
        </Space>
      ),
    },
  ];

  return <Table columns={columns} dataSource={merchants} loading={loading} />;
};

export default NewMerchants;
