import { Card, Tabs } from 'antd';
import React from 'react';
import MerchatDetail from './MerchantDetail/MerchatDetail';

import MerchantTransactions from './MerchantTransactions/MerchantTransactions';

const { TabPane } = Tabs;
const MerchantRightTab = ({merchant, updateMerchant, loading, verifyMerchantTIN, changeMerchantTINStatus=(payload)=>{}, resendVerificationCode=(payload) => { }, onMerchantChange=()=>{ }, cards =[], transactions =[], loadingTransactions = false}) => {
  
    return (
        <Card bordered={false}>
             <Tabs>
                    <TabPane tab="MERCHANT DETAILS" key="m_details">
                        <MerchatDetail updateMerchant={updateMerchant} loading={loading} cards={cards} merchant={merchant} verifyMerchantTIN={verifyMerchantTIN} changeMerchantTINStatus={changeMerchantTINStatus} onMerchantStatusChange={onMerchantChange} onResendVerification={resendVerificationCode} />
                    </TabPane>

                    <TabPane tab="MERCHANT TRANSACTIONS" key="m_transactions">
                        <MerchantTransactions transactions={transactions} merchant={merchant} loading={loadingTransactions} />
                    </TabPane>
                </Tabs>
        </Card>
    );
}


export default MerchantRightTab;