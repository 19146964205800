const { default: apiClient } = require("../Client");

const login = (payload) => apiClient.post('users/login', payload);

const currentUser = () => apiClient.get('users/details?include_single_user_tests');

const requestPasswordReset = (payload) => apiClient.post('users/send-password-reset-link', payload);

const resetPassword = (payload) => apiClient.post('users/reset-password', payload);

export default {
    login, requestPasswordReset, resetPassword, currentUser
}