import React from 'react';
import { Card, Tabs } from 'antd';
import NewGiveAway from './NewGiveAway/NewGiveAway';
import colors from '../../../../configs/colors';

const { TabPane } = Tabs;

const GiveWayLeftTabs = ({giveAway, onClearGiveAway, onAddGiveAway = (values) => {}, loading}) => {
    return (
        <div className='grid grid-cols-1'>
            <Card bordered={false}>
                <Tabs>
                    <TabPane tab="Merchant Dashboard Give-Aways" key="1">
                        <h4 className="text-green-800 text-lg font-semibold" style={{color: colors.primary}}>ORGANIZATION DETALS</h4>
                        <NewGiveAway onClearGiveAway={onClearGiveAway} giveAway={giveAway} onAddGiveAway={onAddGiveAway} loading={loading} />
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    );
}


export default GiveWayLeftTabs;