const { default: apiClient } = require("../Client");

const fetchResources = (params) => apiClient.get('merchant-resources', params);

const removeResource = (resource_id) => apiClient.delete(`merchant-resources/${resource_id}`);

const storeResource = (payload) => apiClient.post(`merchant-resources?include_single_merchant_resource_tests`, payload);


const updateResource = (resource_id, payload) => apiClient.put(`merchant-resources/${resource_id}?include_single_merchant_resource_tests`, payload);



export default {fetchResources, removeResource, storeResource, updateResource}