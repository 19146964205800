import React from "react";
import { Card } from "antd";
import colors from "../../../configs/colors";

const TrafficAnalytic = ({ title, amount, percent, description, loading = false }) => {
  return (
    <Card style={{ borderRadius: "5px" }} bordered={false} loading={loading}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span
          style={{ fontSize: "10px", lineHeight: "15px", color: "#8798AA" }}
        >
          {title}
        </span>
        <span style={{ fontSize: "28px", lineHeight: "48px", fontWeight: 600 }}>
          {amount}
        </span>
        <div style={{ alignSelf: "flex-end", display: "flex", flexDirection: 'column', position: 'absolute', top: '50px', textAlign: 'right' }}>
          {percent && <span
                                    style={{ fontSize: "14px", lineHeight: "21px", color: percent >= 0 ? colors.success: colors.danger }}
                                  >
                                    {Math.abs(percent)}%
                                  </span>
                                  }
          <span
            style={{ fontSize: "10px", lineHeight: "15px", color: "#8798AA" }}
          >
            {description}
          </span>
        </div>
      </div>
    </Card>
  );
};

export default TrafficAnalytic;
