import { Col, Form, Row, Button, DatePicker, Input, Select } from 'antd';
import React, { useState } from 'react';
import AnchorAsset from '../../assets/dashboard-asset.png';


const { RangePicker} = DatePicker;
const {Option} = Select;

const FilterPane = ({status = 'statuses', 
                    stattuses = [{name: 'Completed', id: 'complete'}, {name: 'Pending', id: 'pending'}, {name: 'Failed', id: 'failed'}],
                    searchSize =12, 
                    searchPlaceholder = 'Search', 
                    showType = false, 
                    types = [{name: 'video', id: 'video'}, {name: 'document', id: 'document'}], 
                    typeName= "types",
                    showStatus= true, showDate = true, showLimit = true, onSearch = (values) => { }, loading= false}) => {
    const [form] = Form.useForm();
    const [showPane, setShowPane] = useState(false);

    const handleFinish = (values) => {
        const search_params = {limit: values.limit || 30};

        if(values.range) {
            search_params.from = values.range[0].format('YYYY-MM-DD') || null;
            search_params.to = values.range[1].format('YYYY-MM-DD') || null;
        }
        search_params.search_string = values.search_string || null; 

        if(showStatus) {
            search_params[status] = values.status || [];
        }

        if(showType) {
            search_params[typeName] = values.types || [];
        }


        onSearch(search_params);

    }

    const onClearForm = () => {
        form.resetFields();
        onSearch({limit: 30});
    }

    const pane = () => (
        <div className="w-50 pb-4 px-3 shadow--perfect rounded bg-gray-200">
                                <h2 className="font-bold text-base pt-4">Filter</h2>
                                <Form form ={form} layout="vertical"
                                        name="filter" onFinish={handleFinish}>
                                    <Row gutter={[16]}>
                                            <Col sm={24} md={searchSize}>
                                                <Form.Item name="search_string">
                                                    <Input placeholder={searchPlaceholder} />
                                                </Form.Item>
                                            </Col>

                                            {showDate && <Col sm={24} md={12}>
                                                <Form.Item name="range">
                                                    <RangePicker 
                                                        style={{width: '100%'}} />
                                                </Form.Item>
                                            </Col>}

                                            {showStatus && <Col sm={24} md={12}>
                                                <Form.Item name="status">
                                                    <Select
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        placeholder="Status"
                                                        mode="multiple"
                                                        >
                                                            {stattuses.map((s, i) => <Option key={s.id + '_' + i} value={s.id}>{s.name}</Option>)}
                                                           
                                                    </Select>
                                                </Form.Item>
                                            </Col>}

                                          

                                            {showLimit && <Col sm={24} md={12} >
                                                <Form.Item name="limit">
                                                    <Input addonBefore="Limit" placeholder="Limit" defaultValue={30}/>
                                                </Form.Item>
                                            </Col>}

                                            {showType && <Col sm={24} md={12}>
                                                <Form.Item name="types">
                                                    <Select
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        placeholder="Type"
                                                        mode="multiple"
                                                        >
                                                            {types.map((t, index) => <Option key={t.id + '_'+ index} value={t.id}>{t.name}</Option>)}
                                                            
                                                           
                                                    </Select>
                                                </Form.Item>
                                            </Col>}

                                            

                                            <Col sm={24} md={24}>
                                                <Button
                                                    htmlType="submit"
                                                    type="primary"
                                                    size="middle"
                                                    loading={loading}
                                                >
                                                    Search
                                                </Button>

                                                <Button
                                                    type="default"
                                                    className="ml-1"
                                                    size="middle"
                                                    
                                                    onClick={onClearForm}
                                                >
                                                    Clear
                                                </Button>
                                            </Col>
                                        </Row>
                                </Form>
                                
                            </div>
    )

    return (
        <Row gutter={[16,16]}>
            <Col sm={24}>
                    <img
                            src={AnchorAsset} 
                            className="w-6 h-full cursor-pointer float-right" 
                            alt="search_fields" 
                            onClick={() => { setShowPane(!showPane) }}
                        />
            </Col>
            { showPane ? <Col sm={24}>{pane()}</Col> : null}
        
        </Row>
    );
}


export default FilterPane;