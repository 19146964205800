const maskData = (data = '', maskWith= "*", save = 3, last= 3) => {
    if (!data || data === '') return '';
    const len = data.length;

    const _begin = data.substr(0, save);
    const _last = data.substr(-last);
    const mask = len - (save + last);

    return _begin + replicate(maskWith, mask) + _last;
}

const replicate = (data = '*', num = 1) => {
    if(num <= 0) return '';

    return data.repeat(num);
}

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export default {maskData, numberWithCommas}