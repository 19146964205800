import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import GiveAwayClient from '../../../api/giveaway/GiveAwayClient';
import StatusIndicatorForm from '../../../components/StatusIndicator/StatusIndicatorForm';
import networkService from '../../../services/network.service';
import notify from '../../../services/notification.service';
import GiveAwayRightTabs from './GiveAwayRightTabs/GiveAwayRightTabs';
import GiveWayLeftTabs from './GiveWayLeftTabs/GiveWayLeftTabs';
import './Promotion.less';

const Promotion = (props) => {
    const [newGiveAways, setNewGiveAways] = useState([]);
    const [giveAwayToEdit, setGiveAwayToEdit]  = useState();
    const [loading, setLoading] = useState(false);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [giveAwayToChangeStatus, setGiveAwayToChangeStatus] = useState();

    const fetchNewGiveAways = async (params = { }) => {
        setLoading(true);
        const response = await GiveAwayClient.giveaways(params);
        setLoading(false);

        if(response.ok) {
            setNewGiveAways(response.data.data.campaigns);
        }
    }

    const handleSelectedGiveAway = (giveAway) => {
        setGiveAwayToEdit(giveAway);
    }

    const onToggleChangeStatusModal = () => {
        setShowChangeStatus(!showChangeStatus);
    }

    const handleChangeStatusForm = async (values) => {
        setLoading(true);
        const response = await GiveAwayClient.changeStatus(giveAwayToChangeStatus?.id, values)
        setLoading(false);

        if(response.ok) {
            notify('success', 'Give Back', 'Give Back Status Changed Successfully');
            const ua = newGiveAways.map((_a) => {
                if(_a.id == response?.data?.data?.give_back_campaign?.id) return response?.data?.data?.give_back_campaign;
                return _a;

            });

            setNewGiveAways(ua);
            // setGiveAwayToChangeStatus( response?.data?.data?.campaign);
           
            onToggleChangeStatusModal();
            return ;

        } else {
           networkService.handleFailed(response);
        }
    }

    const handleGiveAwayToChangeStatus = (giveaway) => {
        setGiveAwayToChangeStatus(giveaway);
        onToggleChangeStatusModal();

    }
    

    useEffect(() => {
        fetchNewGiveAways();
    }, []);
    return (
        <Row gutter={[16,16]}>
            <Col xs={24} sm={24} md={10}>
                <GiveWayLeftTabs onClearGiveAway={() => handleSelectedGiveAway(null)} giveAway={giveAwayToEdit} loading={loading} onAddGiveAway={(values) => fetchNewGiveAways()} />
            </Col>

            <Col xs={24} sm={24} md={14}>
                <GiveAwayRightTabs 
                    changeCampaignStatus={handleGiveAwayToChangeStatus}
                    onSearch={(filter) => fetchNewGiveAways(filter)} 
                    onEditCampaign={handleSelectedGiveAway} 
                    campaigns={newGiveAways} 
                    loading={loading} />
            </Col>
            <StatusIndicatorForm 
                    loading={loading}
                    status={giveAwayToChangeStatus?.status} 
                    showChangeStatus={showChangeStatus} 
                    handleChangeStatusModalClose={onToggleChangeStatusModal} 
                    handleChangeStatusForm={handleChangeStatusForm} />
        </Row>
    );
}


export default Promotion;