import React from 'react';
import { useAuth } from '../../../hooks/useAuth';

import AppHeader from '../../headers/app/AppHeader';
import AuthHeader from '../../headers/auth/AuthHeader';
import MainMenu from '../../MainMenu/MainMenu';

import './AppLayout.css'

const AppLayout = ({children}) => {
    const { isLogin} = useAuth();
    return (
        <div className='AppLayout'>
            {isLogin ? <AppHeader /> : <AuthHeader />}
            <main className="MainContent" style={isLogin ? {background: '#E4F4F4'}: null}>
                {isLogin && <MainMenu />}
                <div style={{margin: '20px 20px', height: '100%'}}>
                {children}
                </div>


            </main>
        </div>
    );
}


export default AppLayout;