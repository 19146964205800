import React, { useContext } from 'react';
import authService from '../services/auth.service';
import AuthService from '../services/auth.service';
import storageService from '../services/storage.service';
import AuthContext from './AuthContext';

export const useAuth = () => { 
    const { isLogin, setLogin, user, setUser} = useContext(AuthContext);


    const login = (data) => {
        storageService.storeToken(data);
        setLogin(true);
     }

    const logout = () => {
        storageService.removeToken();
        authService.setCurrentMenu('/');
        setLogin(false);
    }


    return { isLogin, setLogin, user, setUser, login, logout}
}