const { default: apiClient } = require("../Client");

const fetchTemplates = (params) => apiClient.get('newsletter-templates', params);

const sendCampaign = (payload) => apiClient.post('newsletters?include_single_newsletter_tests', payload);

const addTemplate = (payload) => apiClient.post('newsletter-templates', payload);

const updateTemplate = (id, payload) => apiClient.put('newsletter-templates/' + id + '?include_single_newsletter_tests=&test_type=template', payload);

export default {
    fetchTemplates, sendCampaign, addTemplate, updateTemplate
}