import { Button, Modal, Form, Select, Input } from 'antd';
import React, { useEffect } from 'react';

const { Option } = Select;
const { TextArea } = Input;


const StatusIndicatorForm = ({showChangeStatus, status, loading = false, handleChangeStatusModalClose, handleChangeStatusForm}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if(!showChangeStatus) {
            form.resetFields();
        }else {
            form.setFieldsValue({status: status});
        }
    }, [showChangeStatus]);

    return (
        <Modal title="Change Status" footer={null} visible={showChangeStatus} onCancel={handleChangeStatusModalClose}>


            <Form
                layout="vertical"
                form={form}
                initialValues={{status: null, note: null}}
                onFinish={handleChangeStatusForm}

                >
                <Form.Item
                    label="Status"
                    name="status"
                    rules={[{ required: true, message: 'Please select status' }]}
                >
                    <Select style={{ width: '100%' }}>
                        <Option value="under_review">Under Review</Option>
                        <Option value="approved">Approved</Option>
                        <Option value="suspended">Suspended</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Note"
                    name="note"
                >
                    <TextArea
                        placeholder="Brief Description of Action"
                        />
                </Form.Item>

                <Form.Item >
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Submit
                    </Button>
                </Form.Item>
                </Form>


        </Modal>
    );
}


export default StatusIndicatorForm;