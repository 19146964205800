import { Menu, Dropdown, Button, Avatar, Col, Divider, Row, Space, Modal, Input, Form } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { MessageOutlined, SwapOutlined, MailOutlined, DownOutlined, CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import StatusIndicator from '../../../../../components/StatusIndicator/StatusIndicator';
import colors from '../../../../../configs/colors';
import ECard from '../../../../../components/ecard/Ecard';
import userService from '../../../../../services/user.service';
import Uploader from '../../../../../components/uploader/uploader';

const { SubMenu } = Menu;
const MerchatDetail = ({merchant, updateMerchant, loading, verifyMerchantTIN =()=> { }, changeMerchantTINStatus=(payload)=> {}, onResendVerification = (payload) => { }, onMerchantStatusChange=()=> { },cards = []}) => {
    const [merchantModal, setMerchantModal] = useState(false);
    const [merchantToUpdate, setMerchantToUpdate] = useState();
    const [form] = Form.useForm();
    const [profileUrl, setProfileUrl] = useState(null);
    
    useEffect(() => {
        if (!loading && form) {
            handleMerchantModalClose();
        }
    }, [loading]);



    const handleActions = (e) => {

        if (e.key === 'sms' || e.key === 'email') onResendVerification({verify_option: e.key, user_id: merchant?.manager.email});

        if(e.key === 'to_pending') changeMerchantTINStatus({status: 'pending'});

        if(e.key === 'to_not_found') changeMerchantTINStatus({status: 'not_found'});

        if(e.key === 'to_verify') changeMerchantTINStatus({status: 'verified'});

        if (e.key ==='verify_tin') verifyMerchantTIN();

        if(e.key === 'edit') handleShowMerchantUpdateModal();

    }

    const handleMerchantModalClose = () => {
        setMerchantModal(false);
        clearForm();
    }

    const handleShowMerchantUpdateModal = () => {
        form.setFieldsValue({name: merchant?.name, email: merchant?.email, tin_number: merchant?.tin_number, telephone: merchant?.telephone});
        setMerchantToUpdate({...merchant});
        setMerchantModal(true);
    }

    const clearForm = () => {
        form.resetFields();
        setMerchantToUpdate(null);
        form.setFieldsValue(null);

    }

    const handleMerchantUpdate = (values) => {

        if(profileUrl) {
            values.profile_pic_url = profileUrl;
        }

        updateMerchant(values);
    }

    const menu = (
        <Menu onClick={handleActions}>
          <Menu.Item key="edit" icon={<EditOutlined />}>
            Edit Merchant
          </Menu.Item>
          <Menu.Divider />
          <SubMenu key="resend_verification" title='Resend Verification' icon={<MessageOutlined />}>
            <Menu.Item  key='email'>By Email</Menu.Item>
            <Menu.Item  key='sms'>By SMS</Menu.Item>
          </SubMenu>
     
          <Menu.Divider />
          <Menu.Item key="verify_tin" icon={<CheckCircleOutlined />}>
            Verify TIN
          </Menu.Item>
          <SubMenu key='tin_actions' title='Change TIN status' icon={<SwapOutlined />}>
            <Menu.Item key="to_pending" >
                To Pending
            </Menu.Item>
            <Menu.Item key="to_not_found" >
                To Not Found
            </Menu.Item>
            <Menu.Item key="to_verify" >
                To Verified
            </Menu.Item>
          </SubMenu>

          
        </Menu>
      );
   
    return (
        <div className='MerchatDetail'>
            {!merchant ?  <span style={{color: colors.gray}}>No Merchant Selected.</span>
            : 

            <>
                <Row gutter={[16,16]}>
                <Col xs={24} sm={24} md={12}>
                    <Avatar src={merchant?.logo_url} shape="square" size={70} />
                </Col>

                <Col xs={24} sm={24} md={12} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <Space>
                    <StatusIndicator onClick={onMerchantStatusChange}  status={merchant?.status}  />
                    <Dropdown overlay={menu}>
                        <Button>
                            Actions <DownOutlined />
                        </Button>
                    </Dropdown>
                    </Space>
                </Col>
            </Row>

            <Divider />

            <Row gutter={[16,16]}>
                <Col xs={24} sm={24} md={12}>
                    <span style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Company Name</span>
                    <h4 style={{fontSize: 18, fontWeight: 600}}>{merchant?.name}</h4>
                </Col>

                <Col xs={24} sm={24} md={12} style={{textAlign: 'right'}}>
                <span style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Joined Since: </span>
                <span style={{fontSize: 14, fontWeight: 600}}> {dayjs(merchant?.created_at).format('MMM. YYYY')}</span>
                <br/>

                    <span style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Account ID: </span>
                    <span>{merchant?.id}</span>
                </Col>
            </Row>

            <Divider />

            <Row gutter={[16,16]}>
                <Col xs={20} sm={20} md={24}>
                    <Row gutter={[16,16]}>
                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Manager: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{userService.fullname(merchant?.manager?.person)} ({merchant?.manager?.username})</span> 
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Branch: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.branch || 'N/A'}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Head Office: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.headoffice ? 'Yes' : 'No'}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Address: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.address}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Tel: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.telephone}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Email: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.email}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Type: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.client_type}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>Business Registration #: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.business_registration_number || 'N/A'}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>TIN: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.tin_number}<br/>{merchant?.tin_verification_status}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>CODE: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>{merchant?.code}</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>BALANCE: </h4>
                            <span style={{fontSize: 14, fontWeight: 600}}>BAL: {merchant?.revenue?.balance }</span><br />
                            <span style={{fontSize: 14, fontWeight: 600}}>AVAILABLE: {merchant?.revenue?.available_balance }</span>
                        </Col>

                        <Col xs={24} sm={24} md={8} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>WEBSITE: </h4>
                            <span style={{fontSize: 12, fontWeight: 200, }} title={merchant?.website_url}>{merchant?.website_url || 'N/A'}</span>
                        </Col>

                        <Col xs={24} sm={24} md={24} >
                            <h4 style={{color: colors.primary, fontSize: 12, fontWeight: 600}}>ADDED BY: </h4>
                            <div style={{flex: 1}}>
                                <Avatar src={merchant?.created_by?.profile_pic_url} size={64} />
                                <span style={{fontSize: 14, fontWeight: 600, marginLeft: '8px'}}>{userService.fullname(merchant?.created_by) }</span><br />
                            </div>
                            
                        </Col>

                       

                    </Row>
                </Col>

            </Row>

            <Divider />
            <h4 style={{color: colors.primary, fontSize: 14, fontWeight: 600}}>E-GIFT CARD</h4>

            <Row gutter={[16,16]}>
                <Col sm={24} xs={24} md={24} style={{padding: 16, backgroundColor: '#E4F4F4', minHeight: '200px'}}>
                    <Row gutter={[16,16]} style={{padding: '10px'}}>
                    {cards.map((c, index) => (
                    <Col key={c.id + '_' + index} md={10} >
                        <ECard card={c}  />
                    </Col>))} 
                      
                    </Row>
                </Col>
            </Row>
            </>
        }


<Modal title={"Update Merchant"} footer={null} visible={merchantModal} onCancel={handleMerchantModalClose}>
            <Form
                layout="vertical"
                form={form}
                initialValues={merchantToUpdate}
                onFinish={handleMerchantUpdate}
            >
                <Row>
                    <Col xs={24} sm={24} md={8}>
                        <Uploader onClear={merchantModal} uploadImage={(url) => setProfileUrl(url)} />
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                        <Form.Item hasFeedback name="name" rules={[{required: true, message: 'Please Enter Name'}]}>
                            <Input placeholder="Name" />
                        </Form.Item>

                        <Form.Item hasFeedback name="tin_number" rules={[{required: true, message: 'Please Enter TIN'}]}>
                            <Input placeholder="TIN" />
                        </Form.Item>

                        <Form.Item hasFeedback rules={[{required: true, message: 'Please Enter Email'}]} name="email">
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item name="telephone" >
                            <Input placeholder="Phone Number" />
                        </Form.Item>
                

                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                        </Form.Item>

                       
                    </Col>
                </Row>
            </Form>
        </Modal>

        </div>
    );
}


export default MerchatDetail;