import { Card, Col, Row, Table } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import colors from '../../../configs/colors';
import maskService from '../../../services/mask.service';
import PlatformIndicator from '../../platform-indicator/PlatformIndicator';

import './TopMerchantLocation.css';

const TopMerchantLocation = ({data = {merchants: [], total_records: 0}, loading = false}) => {

      const columns = [
          {
              title: 'Name',
              key: 'name',
              dataIndex: 'name',
              render: (text) => <span style={{fontSize: '14px', color: colors.purple_blue, fontWeight: 600 }}>{text}</span>
          },
          {
            title: 'Location',
            key: 'address',
            dataIndex: 'address',
            render: (text) => <span style={{fontSize: '14px', color: colors.gray }}>{text}</span>
            },
        {
            title: 'Date Joined',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (text) => <span style={{fontSize: '14px', color: colors.gray }}>{dayjs(text).format('MMMM, DD YYYY')}</span>
        }
      ]

    return (
        <Card className='TopMerchantLocation' title={
            <Row>
                <Col xs={24} sm={24} md={8}>
                    <PlatformIndicator platform="Merchants" background="#E5BB28" width={'50%'} radius='20px' />

                    <h4 style={{color: colors.success, fontSize: '13px', fontWeight: 600, paddingLeft: '20px', paddingTop: '20px'}}>Top Merchants</h4>

                    <h4 style={{color: colors.success, fontSize: '13px', fontWeight: 600, paddingLeft: '20px'}}>New Merchants</h4>
                </Col>
                <Col xs={24} sm={24} md={16} style={{display: 'flex', flexDirection: 'column', fontSize: '30px', fontWeight: '600', justifyContent: 'center'}}>
                    <h4 style={{paddingLeft: '20px'}}>{maskService.numberWithCommas(data?.total_records)}</h4>
                </Col>
            </Row>
        }>
            <Table dataSource={data.merchants} columns={columns} loading={loading} />
        </Card>
    );
}


export default TopMerchantLocation;