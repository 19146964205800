import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import MerchantClient from '../../../api/merchant/MerchantClient';
import StatusIndicatorForm from '../../../components/StatusIndicator/StatusIndicatorForm';
import networkService from '../../../services/network.service';
import notify from '../../../services/notification.service';
import utilsService from '../../../services/utils.service';
import MerchantRightTab from './MerchantRightTab/MerchantRightTab';
import MerchantTabs from './MerchantTabs/MerchantTabs';
import MerchantTopRow from './MerchantTopRow/MerchantTopRow';
const Merchant = (props) => {

    const [loading, setLoading] = useState(false);
    const [newMerchants, setNewMerchants] = useState([]);
    const [franchiseMerchants, setFranchiseMerchants] = useState([]);
    const [singleMerchants, setSingleMerchants] = useState([]);
    const [merchant, setMerchant] = useState();
    const [cards, setCards] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [showChangeStatus, setChangeStatus] = useState(false);
    const [totalMerchants, setTotalMerchants] = useState(0);

    const onMerchantSelected = async (_merchant) => {
        setMerchant(_merchant);
        fetchMerchant(_merchant.id);
        await fetchMerchantTransactions(_merchant.id);
        await fetchMerchantCards(_merchant.id);
        
    }

    const fetchMerchant = async (id) => {
        const response = await MerchantClient.fetchMerchant(id);

        if(response.ok) {
           if(response.data.data && response.data.data.merchant) setMerchant(response.data.data.merchant);
        }
    }

    const fetchMerchantCards = async (_merchant_id) => {
        setCards([]);
        if(_merchant_id) {
            const response = await MerchantClient.fetchMerchantCards(_merchant_id);
            if(response.ok) {
                setCards(response.data.data.cards || []);
                
            }
        }
    }

    const fetchMerchantTransactions = async (_merchant_id) => {
        setTransactions([]);
        if(_merchant_id) {
            setLoadingTransactions(true);
            const response = await MerchantClient.fetchMerchantTransactions(_merchant_id);
            setLoadingTransactions(false);
            if(response.ok) {
                setTransactions(response.data.data.transactions);
                
            }
        }
    }



    const fetchNewMerchants = async () => {

        setLoading(true);
        const response = await MerchantClient.fetchMerchants({'statuses[]': 'under_review'});
        setLoading(false);

        if(response.ok) {
            setNewMerchants(response?.data?.data?.merchants);
            setTotalMerchants(response?.data.data?.total_records);

        } else {
            networkService.handleFailed(response);
        }
    }

    const fetchSingleMerchants = async () => {
        setLoading(true);
        const response = await MerchantClient.fetchMerchants({'types[]': 'single'});
        setLoading(false);

        if(response.ok) {
            setSingleMerchants(response?.data?.data?.merchants);


        } else {
            networkService.handleFailed(response);
        }

    }

    const fetchFranchiseMerchants = async () => {
        setLoading(true);
        const response = await MerchantClient.fetchMerchants({'types[]': 'franchise'});
        setLoading(false);

        if(response.ok) {
            const franchises = response?.data?.data?.merchants?.map((f) => {
                return {...f, children: []}
            })
            setFranchiseMerchants(franchises);

        } else {
            networkService.handleFailed(response);
        }
    }

    const fetchFranchisees = async(id) => {
        setLoading(true);
        const response = await MerchantClient.fetchMerchants({'types[]': 'franchisee', parent_id: id});
        setLoading(false);

        if(response.ok) {
            const franchisees = response?.data?.data?.merchants;
            const merchants = franchiseMerchants.map((f) => {
                if(f.id == id) {
                    return {...f, children: franchisees};
                }
                return f;
            })

            setFranchiseMerchants(merchants);

        } else {
            networkService.handleFailed(response);
        }
    }

    const onToggleChangeStatusModal = () => {
        setChangeStatus(() => !showChangeStatus);
    }

    const handleChangeStatusModalClose = () => {
        onToggleChangeStatusModal();
    }

    const handleChangeStatusForm = async (values) => {
        setLoading(true);
        const response = await MerchantClient.changeStatus(merchant?.id, values)
        setLoading(false);

        if(response.ok) {
            notify('success', 'Merchant', 'Merchant Status Changed Successfully');
            setMerchant( response?.data?.data?.merchant);
           
            onToggleChangeStatusModal();

            backgroundServices();

        } else {
            if(response.problem == 'NETWORK_ERROR') {
                notify('error', 'Network Error', 'Please Check Internet');
            } else {
                notify('error', 'Error', response.data?.error_msg);
            }
        }
    }

    const backgroundServices = () => {
        fetchNewMerchants();
        fetchSingleMerchants();
        fetchFranchiseMerchants();
    }

    const handleSendCode = async() => {
        if (!merchant) {
            notify('warning', 'Code', 'Please Select Merchant First!');
            return ;
        }
        setLoading(true);
        const response = await MerchantClient.sendMerchantCode(merchant.id, {merchant_id: merchant.id});
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                notify('success', 'Code', 'Code Sent Successfully');
            }

            return ;
        }

        networkService.handleFailed(response);

    }

    const handleVerifyCode = async(code) => {

        if (!merchant) {
            notify('warning', 'Code', 'Please Select Merchant First!');
            return ;
        }

        if (!code) {
            notify('warning', 'Code', 'Please Enter Code');
            return ;
        }
        setLoading(true);
        const response = await MerchantClient.verifyCode(merchant.id, {code});
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                notify('success', 'Code', 'Code Verified.');
            }

            return ;
        }

        networkService.handleFailed(response);

    }

    const onGenerateMerchantQr = (_merchant) => {
        setLoading(true);
        utilsService.onGenerateQrCode(_merchant.name, _merchant.code);
        setTimeout(() => {
            setLoading(false);
        }, 4000);
    }

    const resendVerificationCode = async (payload) => {
        setLoading(true);
        const response = await MerchantClient.resendVerificationCode(payload);
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                notify('success', 'Verifcation Code', response.data.data.message);
            }
            return ;
        }

        networkService.handleFailed(response);
        
    }

    const changeMerchantTINStatus = async (payload) => {
        setLoading(true);
        const response = await MerchantClient.changeTINStatus(merchant?.id,payload);
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                notify('success', 'TIN Status', response.data.data.message);
                fetchMerchant(merchant?.id);
            }
            return ;
        }

        networkService.handleFailed(response);
        
    }

    const verifyMerchantTIN = async () => {
        
        setLoading(true);
        const response = await MerchantClient.verifyMerchantTIN(merchant?.id);
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                notify('success', 'Verify TIN', response.data.data.message);
                fetchMerchant(merchant?.id);
            }
            return ;
        }

        networkService.handleFailed(response);
        
    }

    const updateMerchant = async (payload) => {
        setLoading(true);
        const response = await MerchantClient.updateMerchant(merchant?.id,payload);
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                notify('success', 'Merchant Updated Successfully.');
                fetchMerchant(merchant?.id);
            }
            return ;
        }

        networkService.handleFailed(response);
        
    }

    useEffect(() => {
        backgroundServices();

    }, []);



    return (
        <div className='Merchant'>
            <Row gutter={[16,16]}>
                <Col xs={24} sm={24} md={24}>
                    <MerchantTopRow total={totalMerchants} loading={loading} onVerifyCodeClicked={handleVerifyCode} onSendCodeClicked={handleSendCode} />
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <MerchantTabs newmerchants={newMerchants} 
                        singleMerchants={singleMerchants} 
                        franchiseMerchants={franchiseMerchants} 
                        onFranchiseExpanded={(id) => fetchFranchisees(id)}
                        onGenerateMerchantQr={onGenerateMerchantQr}
                        loading={loading} onMerchantSelected={onMerchantSelected}  />
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <MerchantRightTab updateMerchant={updateMerchant} loading={loading} verifyMerchantTIN={verifyMerchantTIN} changeMerchantTINStatus={changeMerchantTINStatus} resendVerificationCode={resendVerificationCode} onMerchantChange={onToggleChangeStatusModal} merchant={merchant} cards={cards} transactions={transactions} loadingTransactions={loadingTransactions} />
                </Col>

            </Row>

            <StatusIndicatorForm status={merchant?.status} showChangeStatus={showChangeStatus} loading= {loading} handleChangeStatusForm={handleChangeStatusForm} handleChangeStatusModalClose={handleChangeStatusModalClose} />
        </div>
    );
}


export default Merchant;