import jwt from 'jwt-decode';
import storageService from './storage.service';

const isExpired = (token) => {
    try {
        if(!token) return true;
    
        const result = jwt(token);
    
        if(!result.exp) return true;

        const currentTime = Date.now() / 1000;
        
        return parseInt(result.exp) < currentTime;
    } catch (error) {
        return true;
    }
}

const getToken = () => {
    const token = storageService.restoreToken();
    if(!token) return null;

    return token.token;
}

const decodeToken = (token) => {
    let decoded = { };
    try {
        if(!token) return decoded;
    
        return jwt(token);
    
    } catch (error) {
        
        return decoded;
    }
}



export default {
    isExpired, getToken, decodeToken
}