import { Card, Col, Row, Table, Timeline } from 'antd';
import React, {useState, useEffect} from 'react';
import { EyeOutlined } from '@ant-design/icons';
import LogClient from '../../../../api/logs/LogClient';
import userService from '../../../../services/user.service';
import dayjs from 'dayjs';
import colors from '../../../../configs/colors';
const ActivityLogger = (props) => {
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [log,setLog] = useState(null);

    const columns = [
        {
            title: 'Action',
            dataIndex: 'event',
            key: 'event'
        },
        {
            title: 'Performed At',
            dataIndex: 'item_type',
            key: 'item_type'
        },
        {
            title: 'Performed By',
            key: 'user',
            render: (_log) => <span>{userService.fullname(_log?.actor?.person || {})} [{_log?.actor?.email}]</span>
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'date',
            render: (_date) => dayjs(_date).format('DD MMM YYYY, hh:mm a')
        },
        {
            title: 'View',
            key: 'view',
            render: (_log) => <EyeOutlined onClick={() => setLog(_log)} />
        }
    ]

    const fetchLogs = async (params = {limit: 30}) => {
        setLoading(true);
        const response = await LogClient.fetchLogs(params);
        setLoading(false);

        if(response.ok) {
            const _logs = response.data.data.logs || [];
            setLogs(_logs)
        }
    }

    const extractChanges = (changes = { }) => {
        const _changes = [];
        for(const property in changes) {
            _changes.push({name: property, actions: changes[property]})
        }
        return _changes;
    }

    useEffect(() => {
        fetchLogs();
    }, []);


    return (
        <Row gutter={[16,16]}>
            <Col xs={24} sm={24} md={16}>
                <Card title="Logs.">
                    <Table dataSource={logs} columns={columns} loading={loading} />
                </Card>
            </Col>

            <Col xs={24} sm={24} md={8}>
                <Card title="Log Detail">
                    {!log && <span style={{color: colors.gray}}>No Log Selected.</span>}
                    {log && (
                        <Timeline>

                            {extractChanges(log.changes).map((change, index) => (
                                <div key={'changes_' + change.name + index}>
                                    <Timeline.Item color="green"><span style={{color: colors.danger}}>What Changed: {change.name}</span></Timeline.Item>
                                    <Timeline.Item><b>From: </b> {change.actions[0] || ''}</Timeline.Item>
                                    <Timeline.Item><b>To: </b> {change.actions[1] || ''}</Timeline.Item>
                                    
                                </div>
                            ))}

                      </Timeline>
                    )}
                </Card>
            </Col>
        </Row>
    );
}


export default ActivityLogger;