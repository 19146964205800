import { Button, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import onboardingClient from '../../../api/onboardingimages/onboardingClient';
import UserClient from '../../../api/users/UserClient';
import authService from '../../../services/auth.service';
import AccountEmployees from './AccountEmployees/AccountEmployees';
import './AccountManagement.less';
import ActivityLogger from './ActivityLogger/ActivityLogger';
import OnboardingImageTabs from './OnboardingImages/OnboardingImageTabs';

const AccountManagement = (props) => {
    const [view, setView] = useState(null);
    const [adImages, setAdImages] = useState([]);
    const [resources, setResources] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeeLoading, setEmployeeLoading] = useState(false);

    const fetchImages = () => {
        onboardingClient.fetchImages().then((res) => {
            if(res.ok) {
                setAdImages(res.data.data.images || []);
            }
        });
    }

    const fetchEmployees = async (params) => {
        setEmployeeLoading(true);
        const response = await UserClient.fetchEmployees(params);
        setEmployeeLoading(false);

        if(response.ok) {
            const _employees = response.data.data.users || [];
            setEmployees(_employees);
            
        }

    }

    const fetchResources = async() => {

        const response = await UserClient.fetchResources();

        if(response.ok) {
            const _resources = response.data.data.resources || [];
            setResources(_resources);
        }

    }

    const onImageRemove = (id) => {
        const _images = adImages.filter((i) => i.id !== id);
        setAdImages(_images);
    }

    useEffect(() => {
        fetchImages();
        fetchResources();
        fetchEmployees();
    }, []);
    return (
        <div className='AccountManagement'>
            <div className="mb-5" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Space>
                    {authService.hasPermission('users') && <Button type={view == 'employees' ? 'primary' : 'default'} onClick={() => setView('employees')} className="log-button-with">Employees</Button>}
                    
                    {authService.hasPermission('activity_logs') && <Button type={view == 'logs' ? 'primary' : 'default'} onClick={() => setView('logs')} className="log-button-with">Logs</Button>}

                     {/* sign up image type | login or sign up */}
                     {authService.hasPermission('marketing') && <Button type={view == 'sign-up-images' ? 'primary' : 'default'} onClick={() => setView('sign-up-images')} className="log-button-with">Sign Up Images</Button>}
                    
                </Space>
            </div>

            {view === 'employees' && <AccountEmployees onSearchEmployees={fetchEmployees} employees={employees} loading={employeeLoading} resources={resources} />}

            {view === 'logs' && <ActivityLogger />}

            {view === 'sign-up-images' && <OnboardingImageTabs onImageRemove={onImageRemove} images={adImages} />}
        </div>
    );
}


export default AccountManagement;