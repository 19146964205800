import dayjs from 'dayjs';

const transformTraffic = (_data, date) => {
    const data = [];
    if(_data) {
        let description = 'This Month';
        if(date) {
            description = dayjs(date).format('MMM YYYY');
        }
        
        for(let key in _data) {
            data.push({title: key.replaceAll("_", " ").toUpperCase(), description, ..._data[key]});
        }
    }

    return data;
    
}

export default {
    transformTraffic
}