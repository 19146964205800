import { Button, DatePicker, Form, Input, Select, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment'
import React, { useEffect, useState } from 'react';
import GiveAwayClient from '../../../../../api/giveaway/GiveAwayClient';
import Uploader from '../../../../../components/uploader/uploader';
import colors from '../../../../../configs/colors';
import networkService from '../../../../../services/network.service';
const { TextArea } = Input;

const NewGiveAway = ({giveAway, onClearGiveAway = () => { }, onAddGiveAway = (values) => {}}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [clearData, setClearData] = useState();

    const handleAddGiveAway= async (values) => {

        const payload = {...values};
        if(imageUrl) {
            payload.images = [{url: imageUrl}];
        }
        setLoading(true)
        const response = !giveAway ? await GiveAwayClient.addGiveAway(payload) : await GiveAwayClient.updateGiveAway(giveAway.id, payload)
        setLoading(false);
    
        if(response.ok) {
            onAddGiveAway(values);
            form.resetFields();
            setClearData(new Date().getTime());

        } else {
            networkService.handleFailed(response);
        }
    }

    const clearForm = () => {
        form.resetFields();
        onClearGiveAway();
    }
  
    useEffect(() => {
        
        if(giveAway) {
            form.setFieldsValue({...giveAway, start_date: moment(giveAway.start_date), end_date: moment(giveAway.end_date)});
        }
    }, [giveAway]);
    return (
        <div className='NewGiveAway'>
            <Form 
                form={form}
                layout="vertical"
                onFinish={handleAddGiveAway}
                initialValues={{name: '', organization: '', contact_person_first_name: null, status: 'under_review'}}>

                <Form.Item label="Name" name="name" hasFeedback rules={[{required: true, message: 'Please Enter Name'}]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Organization" name="organization" hasFeedback rules={[{required: true, message: 'Please Enter Organization'}]}>
                    <Input />
                </Form.Item>

                <div className="grid md:grid-cols-2 gap-4">
                    <Form.Item label="Contact Person First Name" name="contact_person_first_name" hasFeedback rules={[{required: true, message: 'Please Enter Contact Person'}]}>
                        <Input />
                    </Form.Item>

                    <Form.Item label="Contact Person Surname" name="contact_person_surname" hasFeedback rules={[{required: true, message: 'Enter Contact Person Surname'}]}>
                        <Input />
                    </Form.Item>
                </div>

              

                <Form.Item label="Position" name="position" hasFeedback rules={[{required: true, message: 'Please Enter Position'}]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Address" name="address">
                    <TextArea rows={2} />
                </Form.Item>

                <div className="grid md:grid-cols-2 gap-4">
                    <Form.Item label="Tel." name="telephone" >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Email" name="email" >
                        <Input type="email" />
                    </Form.Item>
                </div>

                <Form.Item label="Website" name="website_url"  hasFeedback rules={[{required: true, message: 'Please Enter Website'}]}>
                        <Input />
                </Form.Item>
                <Form.Item label="Description" name="description" hasFeedback rules={[{required: true, message: 'Please Enter Something brief about campaign'}]}>
                    <TextArea rows={2} />
                </Form.Item>
                <div className="grid md:grid-cols-2 gap-4">
                    <Form.Item label="Start Date" name="start_date" hasFeedback rules={[{required: true, message: 'Please Select Start Date'}]}>
                        <DatePicker />
                    </Form.Item>

                    <Form.Item label="End Date" name="end_date" hasFeedback rules={[{required: true, message: 'Please Select Start Date'}]}>
                        <DatePicker />
                    </Form.Item>
                </div>
                {/* <Form.Item label="Status" name="status">
                    <Select>
                        <Select.Option value="under_review">Under Review</Select.Option>
                        <Select.Option value="approve">Approve</Select.Option>
                        <Select.Option value="suspend">Suspend</Select.Option>
                    </Select>
                </Form.Item> */}

                <Uploader thumbBack={ (giveAway && giveAway.images && giveAway.images.length > 0) ? giveAway.images[0].url:null} uploadImage={(url) => setImageUrl(url)} onClear={clearData} />

                <Form.Item >
                            <Space>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Save
                            </Button>

                            <Button type="primary" onClick={clearForm} style={{backgroundColor: colors.danger, borderColor: 'transparent'}} loading={loading}>
                                Cancel
                            </Button>
                            </Space>
                </Form.Item>
            </Form>
        </div>
    );
}


export default NewGiveAway;