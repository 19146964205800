import React, { useState } from 'react';
import { Button, Col, Dropdown, DatePicker, Row, Table, Select, Form, Input } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import StatusIndicator from '../../../../../components/StatusIndicator/StatusIndicator';
import AnchorAssert from "../../../../../assets/dashboard-asset.png"
import { EyeOutlined } from '@ant-design/icons';
import colors from '../../../../../configs/colors';

const { RangePicker } = DatePicker;
const { Option } = Select;

const NewGiveAwayTable = ({campaigns, changeCampaignStatus = (c) => { }, onSearch = (filter) => { }, onEditCampaign = (campaign) => { }, loading}) => {
    const [showFilter, setShowFilter] = useState(false);


    const [form] = Form.useForm();





    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            key: 'organization',
        },
        {
            title: 'Contact Person',
            key: 'contact_person',
            render: (campaign) => campaign.contact_person_first_name + ' ' + campaign.contact_person_surname
        },
        {
            title: 'Telephone',
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: 'Date',
            key: 'created_at_date',
            render: (campaign) => dayjs(campaign.start_date).format("DD.MMM.YYYY") + ' - ' + dayjs(campaign.end_date).format("DD.MMM.YYYY")
        },
        {
            title: 'Status',
            key: 'status',
            render: (text) => <StatusIndicator onClick={() => changeCampaignStatus(text)} status={text.status} />
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text) => <EyeOutlined color={colors.primary} onClick={() => onEditCampaign(text)} />
        }
    ];


    const handleFinish = (values) => {
        const search_params = {limit: values.limit || 30};

        if(values.range) {
            search_params.from = values.range[0].format('YYYY-MM-DD') || null;
            search_params.to = values.range[1].format('YYYY-MM-DD') || null;
        }
        search_params.search_string = values.search_string || null; 

        onSearch(search_params);

    }

    const onClearForm = () => {
        form.resetFields();
        onSearch({limit: 30});
    }


    const FilterPane = () => (<div className="w-50 pb-4 px-3 shadow--perfect rounded bg-gray-200">
                                <h2 className="font-bold text-base pt-4">Filter</h2>
                                <Form form ={form} layout="vertical"
                                        name="filter" onFinish={handleFinish}>
                                    <Row gutter={[16]}>
                                            <Col sm={24} md={6}>
                                                <Form.Item name="search_string">
                                                    <Input placeholder="Search" />
                                                </Form.Item>
                                            </Col>

                                            <Col sm={24} md={12}>
                                                <Form.Item name="range">
                                                    <RangePicker 
                                                        style={{width: '100%'}} />
                                                </Form.Item>
                                            </Col>

                                          

                                            <Col sm={24} md={6} >
                                                <Form.Item name="limit">
                                                    <Input addonBefore="Limit" placeholder="Limit" defaultValue={30}/>
                                                </Form.Item>
                                            </Col>

                                            

                                            <Col sm={24} md={24}>
                                                <Button
                                                    htmlType="submit"
                                                    type="primary"
                                                    size="middle"
                                                >
                                                    Search
                                                </Button>

                                                <Button
                                                    type="default"
                                                    className="ml-1"
                                                    size="middle"
                                                    onClick={onClearForm}
                                                >
                                                    Clear
                                                </Button>
                                            </Col>
                                        </Row>
                                </Form>
                                
                            </div>)

    return (
          <Row gutter={[16,16]}>
          <Col xs={24}>
                    <img
                            src={AnchorAssert} 
                            className="w-6 h-full cursor-pointer float-right" 
                            alt="search_fields" 
                            onClick={() => { setShowFilter(!showFilter) }}
                        />
          </Col>

          {
              showFilter && <Col xs={24} >{FilterPane()}</Col>
          }

          <Col xs={24}>
            <div style={{overflow: 'auto'}}>
              <Table dataSource={campaigns} columns={columns} loading={loading} pagination={{ pageSize: 20 }} />
            </div>
          </Col>
      </Row>
    );
}


export default NewGiveAwayTable;