import { Card, Col, Row, Table, Tag, Form, Space, Select, Button, Input } from 'antd';
import React, {useEffect, useState} from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import FilterPane from '../../../components/Filter/FilterPane';
import FundRequestClient from '../../../api/fundrequest/FundRequestClient';
import colors from '../../../configs/colors';
import networkService from '../../../services/network.service';

const statusColors = {
    cancelled: 'volcano',
    declined: '#f50',
    processed: 'green'
}

const fundStates = [
    {name: 'Cancelled', id: 'cancelled'},
    {name: 'Declined', id: 'declined'},
    {name: 'Processed', id: 'processed'}
];

const fundTests = [
    {
        "id": 2,
        "amount": "1000.0",
        "state": "processed",
        "merchant": {
          "id": 4,
          "name": "Single"
        },
        "note": "Cancelled by merchant",
        "receipt": "da974e6e-c722-4bb6-b9f7-2e7967822328",
        "created_at": "2021-03-01T19:09:17.891Z"
    },
    {
        "id": 3,
        "amount": "1000.0",
        "state": "Declined",
        "merchant": {
          "id": 4,
          "name": "Single"
        },
        "note": "Cancelled by merchant",
        "receipt": "da974e6e-c722-4bb6-b9f7-2e7967822328",
        "created_at": "2021-03-01T19:09:17.891Z"
    }
    
];

const { Option } = Select;

const FundRequest = (props) => {
    const [requestData, setRequestData] = useState({request: null, type: null});
    const [fundRequests, setFundRequests] = useState(fundTests);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();


    const FundStatusIndicator = ({status = ''}) =>{
        const color = statusColors[status.toLowerCase()];
        return <Tag color={color}>{status}</Tag>
    }

    const fetchFundRequest = async (params= { limit: 30}) => {
        setLoading(true);
        const response = await FundRequestClient.fetchRequests(params);
        setLoading(false);

        if(response.ok) {
            const _requests = response.data.data?.fund_requests || [];
            // setFundRequests(_requests);
        }
    }

    const handleRequestProcessSelected = (request, type) => {
        setRequestData({request, type});
    }

    const handleRequestCancel = () => {
        
        setRequestData({request: null, type: null});
        form.resetFields();
    }

    const onProcessRequest = async (values) => {
        if(!values.note) return ;
        const payload = values;
        if(values.action == 'process') {
            payload.receipt = requestData?.request?.receipt;
        }

        const response  = values.action == 'process' ? await FundRequestClient.processRequest(requestData.request.id, payload)
                                                        : await FundRequestClient.declineRequest(requestData.request.id, payload);
        if(response.ok) {

        }

        networkService.handleFailed(response);
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'fund_request_id',

        },
        {
            title: 'Merchant',
            dataIndex: 'merchant',
            key: 'fund_request_merchant',
            render: (merchant) => merchant?.name
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'fund_request_amount',

        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'fund_request_state',
            render: (text) => <FundStatusIndicator status={text} />
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'fund_request_note',

        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'fund_request_date',
            render: (_date) => dayjs(_date).format('DD/MM/YYYY HH:mm:ss')

        },
        {
            title: 'Actions',
            key: 'fund_request_actions',
            render: (text) => (
                <Space>
                     <Button type="primary" title="process/decline" icon={<CheckOutlined  />} onClick={() => handleRequestProcessSelected(text, 'process')}></Button>
                     <Button type="default" title="Decline"  icon={<CloseOutlined  style={{color: colors.danger}} />} onClick={() => handleRequestProcessSelected(text, 'decline')}></Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        fetchFundRequest();
    }, []);

    return (
        <div className='FundRequest'>
            <Row gutter={[16,16]}>
                <Col sm={24} md={requestData.request ? 18: 24}>
                    <Card title="Fund Requests">
                        <Row gutter={[16,16]}>
                            <Col sm={24} md={24}>
                                <FilterPane stattuses={fundStates}  onSearch={(params) => fetchFundRequest(params)}  />
                            </Col>
                        </Row>

                        <Row gutter={[16,16]}>
                            <Col sm={24} md={24}>
                                <Table dataSource={fundRequests} columns={columns} loading={loading} />
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col sm={24} md={requestData.request ? 6: 0}>
                    <Card title={'Process/Decline Request'}>

                    <Form
                            layout="vertical"
                            form={form}
                            initialValues={{ status: null, cheque_number: null, amount: null, note: null }}
                            onFinish={onProcessRequest}
                        >
                            
                            <span>ID: {requestData?.request?.id}</span>

                            <Form.Item label="Naration" name="naration">
                                <Select>
                                    <Option value="cheque">Cheque</Option>
                                    <Option value="bank_deposit">Bank Deposit</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item label="Cheque Number" name="cheque_number">
                                <Input  />
                            </Form.Item>

                            <Form.Item label="Amount" name="amount">
                                <Input  />
                            </Form.Item>

                            
                            <Form.Item label="note" name="note" style={{marginTop: '16px'}}>
                                <Input.TextArea rows={2} placeholder="Add Note" />
                            </Form.Item>

                            <Form.Item label="Action" name="action">
                                <Select>
                                    <Option value="process">Process</Option>
                                    <Option value="decline">Decline</Option>
                                </Select>
                            </Form.Item>
                         
                            <Form.Item>
                                
                                <Button type="primary" htmlType="submit" loading={loading}>{requestData?.type == 'process' ? 'Process' : 'Decline' }</Button>
                                <Button type="link"  htmlType="reset" style={{color: colors.danger}} onClick={handleRequestCancel}>Cancel</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}


export default FundRequest;