import { Card, Col, Row, Table } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import React from 'react';
import colors from '../../../configs/colors';
import maskService from '../../../services/mask.service';


import './TopMerchantSale.css';

const TopMerchantSale = ({merchants = [], loading = false}) => {

      const percentChange = (current, previous) => {
          if(current == 0) return 0;
          const percent = ((current - previous)/current) * 100;
          return +percent.toFixed(2);
      }

      const columns = [
          {
              title: 'Name',
              key: 'name',
              dataIndex: 'name',
              render: (text) => <span style={{fontSize: '14px', color: colors.purple_blue, fontWeight: 600 }}>{text}</span>
          },
          {
            title: 'Location',
            key: 'address',
            dataIndex: 'address',
            render: (text) => <span style={{fontSize: '14px', color: colors.gray }}>{text}</span>
            },
            {
                title: 'Current Sales',
                key: 'current_sales',
                dataIndex: 'current_sales',
                render: (text) => <span style={{fontSize: '14px', color: colors.gray }}>{maskService.numberWithCommas(text)}</span>
            },
            {
                title: 'Prev. Yr',
                key: 'previous_sales',
                dataIndex: 'previous_sales',
                render: (text) => <span style={{fontSize: '14px', color: colors.gray }}>{maskService.numberWithCommas(text)}</span>
            },
            {
                title: '%',
                key: 'percent_change',
                render: (_data) => (<span style={{fontSize: '14px', color: percentChange(_data.current_sales, _data.previous_sales) >= 0 ? colors.success: colors.danger }}>
                                        {percentChange(_data.current_sales, _data.previous_sales) >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {Math.abs(percentChange(_data.current_sales, _data.previous_sales))}%</span>)
            }
      ]

    return (
        <Card className='TopMerchantSale' title={
         <h4 style={{color: colors.success, fontWeight: 400}}>Top Merchants</h4>
        }>
            <Table dataSource={merchants} columns={columns} loading={loading} />
        </Card>
    );
}


export default TopMerchantSale;