import { Card, Col, Row, Table } from "antd";
import dayjs from "dayjs";
import React, {useEffect} from "react";
import colors from "../../configs/colors";
import maskService from "../../services/mask.service";
import userService from "../../services/user.service";
import PlatformIndicator from "../platform-indicator/PlatformIndicator";

import "./CurrentUsers.less";

const CurrentUsers = ({users=[], userStats = { }, loading = false }) => {

  const columns = [
      {
          title: 'Name',
          key: 'name',
          dataIndex: 'person',
          render: (p) => <span style={{fontSize: '9px', fontWeight: 400, color: colors.purple_blue}}>{userService.fullname(p)}</span> 
      },
      {
          title: 'Cell Number',
          key: 'cell_number',
          dataIndex: 'phone_number',
          render: (text) => <span style={{fontSize: '9px', color: colors.gray}}>{maskService.maskData(text, '*')}</span>
      },
      {
          title: 'Date',
          key: 'date',
          dataIndex: 'created_at',
          render: (text) => <span style={{fontSize: '9px', color: colors.gray}}>{dayjs(text).format('DD/MM/YY')}</span>
      },
      {
        title: 'Time',
        key: 'time',
        dataIndex: 'created_at',
        render: (text) => <span style={{fontSize: '9px', color: colors.gray}}>{dayjs(text).format('hh:mma')}</span>
    }
  ];

  return (
    <Card
      className="CurrentUsers"
      title={
        <div style={{ display: "flex" }}>
          <div
            style={{
              display: "flex",
              marginRight: "10px",
              alignItems: "center",
            }}
          >
            <PlatformIndicator platform="Mobile" background="#E5BB28" radius='20px' />
          </div>
          <Row style={{ display: "flex", flex: 1 }}>
            <Col
              xs={24}
              sm={24}
              md={8}
            >

              <Row gutter={8}>
                <Col sm={24}>
                      <span
                      style={{
                        color: colors.gray,
                        fontSize: "14px",
                        lineHeight: "12px",
                        fontWeight: 400,
                        textTransform: "uppercase",
                      }}
                    >
                      New Sign ups
                    </span>
                </Col>

                <Col sm={24}>
                    <Row gutter={8}>
                        <Col md={12}>
                          <Row gutter={8}>
                            <Col md={24}>
                              <span
                                style={{
                                  color: colors.gray,
                                  fontSize: "10px",
                                  lineHeight: "12px",
                                  textTransform: "uppercase",
                                }}
                              >
                                New Users
                              </span>
                            </Col>

                            <Col md={24}>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#003F2D",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {userStats?.new_users?.count}
                                </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={12}>
                          <Row gutter={8}>
                                  <Col md={24}>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: colors.gray,
                                      }}
                                    >
                                      % Change
                                    </span>
                                  </Col>

                                  <Col md={24}>
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        color: userStats?.new_users?.comparison_percentage >= 0 ?  colors.success : colors.danger,
                                      }}
                                    >
                                      {userStats?.new_users?.comparison_percentage}%
                                    </span>
                                  </Col>
                          </Row>
                        </Col>
                    </Row>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={16}
            >

              <Row gutter={8}>
                <Col md={24}>
                  <span
                  style={{
                    color: colors.gray,
                    fontSize: "14px",
                    lineHeight: "12px",
                    fontWeight: 400,
                    textTransform: "uppercase",
                  }}
                >
                  Current Users
                </span>
                </Col>

                <Col md={24}>
                  <Row gutter={8}>

                    <Col md={12}>
                      <Row gutter={8}>

                        <Col md={24}>
                                <span
                                      style={{
                                        fontSize: "10px",
                                        color: colors.gray,
                                      }}
                                    >
                                      iOS
                                    </span>
                        </Col>

                        <Col md={24}>
                              <span
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: colors.primary,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {userStats?.users?.ios_count}
                              </span>
                        </Col>

                      </Row>
                    </Col>

                    <Col md={12}>
                      <Row gutter={8}>

                        <Col md={24}>
                            <span
                                style={{
                                  fontSize: "10px",
                                  color: colors.gray,
                                }}
                              >
                                Android
                              </span>
                        </Col>

                        <Col md={24}>
                            <span
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: colors.primary,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {userStats?.users?.android_count}
                              </span>
                        </Col>

                      </Row>
                    </Col>

                  </Row>
                </Col>
              </Row>

            </Col>
          </Row>
        </div>
      }
    >
        <Table columns={columns} dataSource={users} loading={loading} />
    </Card>
  );
};

export default CurrentUsers;
