import { Card, Col, DatePicker, Select, Row, Table, Button, Space, notification } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ReportClient from "../../../api/reports/ReportClient";
import TrafficAnalytic from "../../../components/Analytics/traffic-analytics/TrafficAnalytic";
import PlatformIndicator from "../../../components/platform-indicator/PlatformIndicator";
import urls from "../../../configs/urls";
import networkService from "../../../services/network.service";
import notify from "../../../services/notification.service";
import userService from "../../../services/user.service";
import "./Report.less";

const { RangePicker } = DatePicker;
const {Option} = Select;
const checkAfter = 15 * 1000;

const Report = (props) => {
  const [loading, setLoading] = useState(false);
  const [transactionLoading, setTransactionLoading] = useState(false);
  const [transactionReportLoading, setTransactionReportLoading] = useState(false);
  const [generalStats, setGeneralStats] = useState([{}, {}, {}, {}]);
  const [transactionSearch, setTransactionSearch] = useState({limit: 15});
  const [transactionReportSearch, setTransactionReportSearch] = useState({limit: 15, 'transaction_types[]': 'deposits'});
  const [transactions, setTransactions] = useState([]);
  const [reportTransactions, setReportTransactions] = useState([]);
  const [intervals, setIntervals] = useState([])

  const handleFormChange = (name, value) => {
         const current = {...transactionSearch};
      if(name === 'range' && value) {
          current['from'] = value[0];
          current['to'] = value[1];
      } 
      else {
          current[name] = value;
      }
      setTransactionSearch(current);
  }

  const handleReportFormChange = (name, value) => {
    const current = {...transactionReportSearch};
    if(name === 'range' && value) {
        current['from'] = value[0];
        current['to'] = value[1];
    } 
    else {
        current[name] = value;
    }
    setTransactionReportSearch(current);
  }

  const fetchGeneral = async () => {
    const response = await ReportClient.fetchGeneralStats();
    if (response.ok) {
      const data = response.data.data;
      const _stats = [
        {
          title: "LAST DEPOSIT",
          amount: data?.last_deposit,
          description: dayjs().format("DD.MMM.YYYY"),
        },
        {
          title: "TOTAL DEPOSIT THIS MONTH",
          amount: data?.deposits,
          description: dayjs().format("DD.MMM.YYYY"),
        },
        {
          title: "CASHED-OUT DEPOSIT",
          amount: 0,
          description: dayjs().format("DD.MMM.YYYY"),
        },
        {
          title: "GIVE BACK PROMO",
          amount: data?.kick_back?.amount,
          percent: data?.kick_back?.comparison_percentage,
          description: dayjs().format("DD.MMM.YYYY"),
        },
      ];
      setGeneralStats(_stats);
    }
  };

  const fetchTransactions = async () => {
  
      setTransactionLoading(true);
      const response = await ReportClient.fetchTransactions(transactionSearch);
      setTransactionLoading(false);

      if(response.ok) {
          setTransactions(response.data.data.transactions || []);
      } else {
          networkService.handleFailed(response);
      }
  }

   const fetchReportTransactions = async () => {
  
      setTransactionReportLoading(true);
      const response = await ReportClient.fetchTransactions(transactionSearch);
      setTransactionReportLoading(false);

      if(response.ok) {
        setReportTransactions(response.data.data.transactions || []);
      } else {
          networkService.handleFailed(response);
      }
  }

  const initiateReportGenerator = async (reportType='transactions') => {
    const payload = reportType == 'transactions' ? {...transactionSearch} : {...transactionReportSearch};
    if(!payload.file_type) {
        payload.file_type = 'pdf';
    }
    if(reportType == 'transactions') {
        setTransactionLoading(true);
    } else {
        setTransactionReportLoading(true);
    }
    const response = await ReportClient.initiateReportGenerator(payload);
    if(reportType == 'transactions') {
        setTransactionLoading(false);
    } else {
        setTransactionReportLoading(false);
    }

    if(response.ok) {
      notify('success', 'Report Queued', 'You will be notified when the process is completed.');
      if(response.data.data.report.status == 'generated') {
          downloadReport(response.data.data.report.id);
      } else {
          checkReportStatus(response.data.data.report.id);
      }
    } else {
        networkService.handleFailed(response);
    }
  }

  const downloadReport = (report_id) => {

    const key = `open${Date.now()}`;
    const btn = (
        <Button type="primary" size="small" onClick={() => {notification.close(key);window.open(urls.base + `/reports/${report_id}/download`, "_blank");}}>
            Download
        </Button>
    );
    notification.open({
        message: 'Report Available.',
        description:
        'Click on the button below to download.',
        btn,
        key,
        duration: 0
    });
  }

  const checkReportStatus = async (report_id) => {
      try {
          const _interval = setInterval( async () => {
            const response = await ReportClient.checkReportStatus(report_id);
            if(response.ok) {
                if(response.data.data.report.status == 'generated') {
                    clearInterval(_interval);
                    intervals.forEach((inter) => {
                        clearInterval(inter);
                    });
                    setIntervals([]);
                    downloadReport(report_id);

                } else {
                    const _preIntervals = intervals;
                    _preIntervals.push(_interval);
                    setIntervals(_preIntervals);
                }
            }
          }, checkAfter);
          
      }catch(e) {
          console.log(e);
      }
  }

  const transactionColumn = [
        {
            title: 'Customer',
            key: 'merchant',
            render: (_transaction) => userService.fullname(_transaction.person)
        },
        {
            title: 'Merchant',
            dataIndex: 'merchant',
            key: 'merchant'
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Type',
            dataIndex: 'transaction_type',
            key: 'transaction_type'
        },

        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_date) => dayjs(_date).format('DD/MM/YYYY HH:mm:ss')
        },

  ]

  const transactionReportColumn = [
    {
        title: 'Type',
        dataIndex: 'transaction_type',
        key: 'transaction_type'
    },
    {
        title: 'Bank',
        dataIndex: 'bank',
        key: 'bank'
    },
    {
        title: 'Date',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (_date) => dayjs(_date).format('DD/MM/YYYY HH:mm:ss')
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => <span className="text-green-500">{text}</span>
    },
   

 

]

  useEffect(() => {
    fetchGeneral();
    fetchTransactions();
    fetchReportTransactions();
  }, []);
  return (
    <div className="Report">
      <Row gutter={[16, 16]}>
        {generalStats.map((g, i) => (
          <Col sm={24} xs={24} md={6} key={'traffic_reports' + i}>
            <TrafficAnalytic
                loading={loading}
              percent={g.percent}
              title={g.title}
              amount={g.amount}
              description={g.description}
            />
          </Col>
        ))}
      </Row>

      <Row gutter={16}>
        <Col sm={24} xs={24} md={12}>
          <Card className="RemovePadding" title={   
                   <>
                   <div  style={{width: '150px'}}>
                       <PlatformIndicator platform="Withdrawals" background="#E5BB28" radius='20px' />
                   </div>   
                   <Row gutter={[16,16]} style={{paddingTop: '16px', paddingLeft: '16px'}}>
                       <Col sm={12} xs={12}>
                           <RangePicker onChange={(values, format) => handleReportFormChange('range', format)} style={{width: '100%'}} />
                       </Col>
   
                       <Col sm={12} xs={12}>
                           <Select
                               allowClear
                               style={{ width: '100%' }}
                               placeholder="Transaction Status"
                               mode="multiple"
                               onChange={(value) => handleReportFormChange('transaction_statuses[]', value)}
                               >
                                   <Option value="complete">Completed</Option>
                                   <Option value="pending">Pending</Option>
                                   <Option value="failed">Failed</Option>
                               </Select>
                       </Col>
   
                       <Col sm={12} xs={12}>
                           <Select
                               allowClear
                               style={{ width: '100%' }}
                               placeholder="Format"
                               onChange={(value) => handleReportFormChange('file_type', value)}
                               >
                                   <Option value="pdf">PDF</Option>
                                   <Option value="csv">CSV</Option>
                               </Select>
                       </Col>
   
                       <Col sm={24} xs={24} style={{textAlign: 'right'}}>
                           <Space>
                               <Button type="primary" onClick={fetchReportTransactions} loading={transactionReportLoading}>Search</Button>
                               <Button type="ghost" onClick={() => initiateReportGenerator('reportTransactions')} loading={transactionReportLoading}>Generate Report</Button>
                           </Space>
                       </Col>
                   </Row>
                 </>
             }>
                 <Table dataSource={reportTransactions} columns={transactionReportColumn} loading={transactionReportLoading} />
          </Card>
        </Col>
        <Col sm={24} xs={24} md={12}>
          <Card  className="RemovePadding" title={   
              <>
                <div  style={{width: '150px'}}>
                    <PlatformIndicator platform="Detail Transactions" background="#E5BB28" radius='20px' />
                </div>   
                <Row gutter={[16,16]} style={{paddingTop: '16px', paddingLeft: '16px'}}>
                    <Col sm={12} xs={12}>
                        <RangePicker onChange={(values, format) => handleFormChange('range', format)} style={{width: '100%'}} />
                    </Col>

                    <Col sm={12} xs={12}>
                        <Select
                            allowClear
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Transaction Type"
                            onChange={(value) => handleFormChange('transaction_types', value)}
                            >
                                <Option value="card_purchase">Card Purchase</Option>
                                <Option value="card_redemption">Card Redemption</Option>
                                <Option value="kick_back">Kick Back</Option>
                            </Select>
                    </Col>

                    <Col sm={12} xs={12}>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Transaction Status"
                            mode="multiple"
                            onChange={(value) => handleFormChange('transaction_statuses[]', value)}
                            >
                                <Option value="complete">Completed</Option>
                                <Option value="pending">Pending</Option>
                                <Option value="failed">Failed</Option>
                            </Select>
                    </Col>

                    <Col sm={12} xs={12}>
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Format"
                            onChange={(value) => handleFormChange('file_type', value)}
                            >
                                <Option value="pdf">PDF</Option>
                                <Option value="csv">CSV</Option>
                            </Select>
                    </Col>

                    <Col sm={24} xs={24} style={{textAlign: 'right'}}>
                        <Space>
                            <Button type="primary" onClick={fetchTransactions} loading={transactionLoading}>Search</Button>
                            <Button type="ghost" onClick={initiateReportGenerator} loading={transactionLoading}>Generate Report</Button>
                        </Space>
                    </Col>
                </Row>
              </>
          }>

              <Table dataSource={transactions} columns={transactionColumn} loading={transactionLoading} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Report;
