import { Button, Card, Checkbox, Col, Row, Table, Select, Spin, Input, Space } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import React, {useRef, useState, useEffect, useCallback} from 'react';
import EmailEditor from 'react-email-editor';
import NewsLetterClient from '../../../api/newsletter/NewsLetterClient';
import sample from '../../../assets/json/template.json';
import sample2 from '../../../assets/json/template2.json';
import FilterPane from '../../../components/Filter/FilterPane';
import colors from '../../../configs/colors';
import debounce from 'lodash/debounce';
import MerchantClient from '../../../api/merchant/MerchantClient';
import notify from '../../../services/notification.service';
import networkService from '../../../services/network.service';
import UserClient from '../../../api/users/UserClient';
import UserService from '../../../services/user.service'

const Option = {Select};

const NewsLetter = (props) => {
    const emailEditorRef = useRef(null);
    const [viewTemplate, setViewTemplate] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchValue, setSearchValue] = useState([]);
    const [searchMerchants, setSearchMerchants] = useState([]);
    const [users, setUsers] = useState([]);
    const [campaignName, setCompaignName] = useState(null);
    const [sendLoading, setSendLoading] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [formData, setFormData] = useState({name: null, include_all_merchants: false, merchant_ids: [], include_all_affiliates: false, user_ids: []})

    const templateColumns = [
        {title: 'Name', dataIndex: 'name', key: 'name'},
        {title: 'Date', dataIndex: 'created_at', key: 'created_at', render: (text) => dayjs(text).format('DD MMM YYYY')},
        {title: 'Action', key: 'view', render: (t) => <EyeOutlined onClick={() => {
            loadTemplate(t.content);  
            if(t.id) {
                setTemplateId(t.id);
                setCompaignName(t.name);
            } 
        }} /> },
    ]

    

    const fetchTemplates = async (params= { limit: 30}) => {
        setLoading(true);
        const response = await NewsLetterClient.fetchTemplates(params);
        setLoading(false);
        let _clean = [];
        if(response.ok) {
            const temps = response.data.data.templates || [];
            _clean = temps.map((t) => {
                try{
                    return {...t, content: JSON.parse(t.content)}
                }catch(e) {
                    return { }
                }
            });
            
        }

        _clean.unshift({name: 'Sample 2', content: sample2, created_at: new Date()});
        _clean.unshift({name: 'Default', content: sample, created_at: new Date()});
        setTemplates(_clean);
    }

    const onLoaded = () => {
        setLoaded(true); 
    }

    const debounceCall = useCallback(debounce((query) => handleFetchMerchants(query), 1000), []);

    const debounceUserCall = useCallback(debounce((query) => handleFetchUsers(query), 1000), []);

    const handleFetchMerchants = async(query) => {
        setSearchLoading(true); 
        const response = await MerchantClient.fetchMerchants({search_string: query});
        setSearchLoading(false);
        if(response.ok) {
            const res = response.data.data?.merchants || [];
            const _merhants = res.map((r) => {
                return {value: r.id, text: r.name}
            } );
            setSearchMerchants(_merhants);
        }
    }

    const handleFetchUsers = async (query) => {
        setSearchLoading(true); 
        const response = await UserClient.fetchEmployees({search_string: query});
        setSearchLoading(false);
        if(response.ok) {
            const res = response.data.data?.users || [];
            const _users = res.map((r) => {
                return {value: r.id, text: UserService.fullname(r?.person)}
            } );
            setUsers(_users);
        }
    }


    const fetchMerchants = (query) => {
        debounceCall(query);
    }

    const fetchUsers = async(query) => {
        debounceUserCall(query);
    }

    const handleChange = (name, value) => {
        const _pre = {...formData, [name]: value};
        setFormData(_pre);
        setSearchMerchants([]);
        setSearchLoading(false);
    }

    const loadTemplate = (content) => {
        try {
            emailEditorRef.current.editor.loadDesign(content);
        } catch (error) {
            
        }
    }

    const sendEmails = async () => {
  
        if (!formData?.name) {
            notify('warning', 'Enter Compaign Name');
            return ;
        }
        emailEditorRef.current.editor.exportHtml(async (data) => {
            const { design, html } = data;
            setSendLoading(true);
            const _merchants_ids = formData.merchant_ids?.map((c) => c.key);
            const _user_ids = formData.user_ids.map((c) => c.key);

            const payload = {content: html , name: formData?.name, include_all_affiliates: formData?.include_all_affiliates, include_all_merchants: formData?.include_all_merchants};

            if(_merchants_ids.length > 0) {
                payload.merchant_ids = _merchants_ids;
            }

            if(_user_ids.length > 0) {
                payload.user_ids = _user_ids;
            }
            console.log(payload);

            const response = await NewsLetterClient.sendCampaign(payload);
            setSendLoading(false);
            if(response.ok) {
                notify('success', 'NewsLetter', 'Sent Successfully');
                if(templateId) {
                    await NewsLetterClient.updateTemplate(templateId, {content: JSON.stringify(design)}).then(() => fetchTemplates())
                } else {
                    await NewsLetterClient.addTemplate({name: formData?.name, content: JSON.stringify(design)}).then(() => fetchTemplates())
                }
                return ;
            }

            networkService.handleFailed(response);
          });
    }

    useEffect(() => {
        fetchTemplates();
    }, []);

    const loadSample = () => { 
        try {
            setViewTemplate(!viewTemplate);
            // emailEditorRef.current.editor.loadDesign(sample);
        } catch (error) {
            
        }
    }



    return (
        <Row gutter={[16,16]}>
            <Col xs={24} sm={24} md={viewTemplate ? 6 : 0}>
                <Card title="Templates">
                    <FilterPane searchSize={24} showStatus={false} showDate={false} showLimit={false} onSearch={fetchTemplates} />

                    <Table dataSource={templates} columns={templateColumns} loading={loading} />
                </Card>
            </Col>

            <Col xs={24} sm={24} md={!viewTemplate ? 24: 18}>
                <Card>
                    <Row gutter={[16,16]}>
                        <Col md={24}>
                            <Row gutter={[16,16]}>
                                <Col sm={24} xs={24} md={4}><Button type="default" onClick={loadSample}>{viewTemplate ? 'Hide': 'View'} Templates</Button></Col>
                                <Col sm={24} xs={24} md={6}>
                                    <Input placeholder="Name of Campaign" value={formData?.name} onChange={(e) => handleChange('name', e.target.value)}  />
                                </Col>
                                <Col sm={24} md={7}>
                                    <Select
                                        mode="multiple"
                                        labelInValue
                                        value={formData?.merchant_ids}
                                        placeholder="Select merchants"
                                        notFoundContent={searchLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onSearch={fetchMerchants}
                                        onChange={(values) => handleChange('merchant_ids', values)}
                                        style={{ width: '100%' }}
                                    >
                                        {searchMerchants.map(d => (
                                        <Option key={d.value + '_merchants'} value={d.value}>{d.text}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col sm={24} md={7}>
                                    <Select
                                        mode="multiple"
                                        labelInValue
                                        value={formData?.user_ids}
                                        placeholder="Select Users"
                                        notFoundContent={searchLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onSearch={fetchUsers}
                                        onChange={(values) => handleChange('user_ids', values)}
                                        style={{ width: '100%' }}
                                    >
                                        {users.map(d => (
                                        <Option key={d.value + '_users'} value={d.value}>{d.text}</Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col sm={24} md={24} style={{textAlign: 'right'}}>
                                    
                                    {isLoaded && (
                                        <Space>
                                            <Checkbox onChange={(e) =>handleChange('include_all_merchants', e.target.checked)} checked={formData?.include_all_merchants}>Include All Merchants</Checkbox>
                                            <Checkbox onChange={(e) =>handleChange('include_all_affiliates', e.target.checked)} checked={formData?.include_all_affiliates}>Include All Affiliates</Checkbox>
                                            <Button type="primary" style={{width: '80px', marginLeft: '8px'}} onClick={sendEmails} loading={sendLoading}>Send</Button>
                                        </Space>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={24}>
                            <EmailEditor
                                    minHeight={640}
                                    onLoad={onLoaded}
                                    ref={emailEditorRef}
                                />
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}


export default NewsLetter;