import React from 'react';

const ECard = ({ card }) => {
    return (
        <div className="w-40 flex flex-col items-center relative">
            <img id="tempId" src={card.image} className="w-full shadow-lg" alt="" />

            <div className="absolute w-full h-full">
                <div className="absolute top-0 right-0 p-2"
                    style={{
                        color: `${card.default_color}`,
                        font_weight: `${card.font_weight}`,
                        fontSize: '0.5rem'
                    }}>
                    ¢{card.amount}
                </div>
                {card.web_design_props && (
                    <>
                        {card.web_design_props.logo_pos !== undefined && (
                            <div className={` ${card.web_design_props.logo_pos[0]}-0 ${card.web_design_props.logo_pos[1] === 'center' ? 'w-full' : `${card.web_design_props.logo_pos[1]}-0`} absolute p-2`}>
                                {card.logo ?
                                    <img src={card.logo} className="w-6 mx-auto" alt="" />
                                    :
                                    <div className="w-12 border-dashed border h-6 flex--basic"
                                        style={{ color: `${card.default_color}`, borderColor: `${card.default_color}` }}
                                    >
                                        Logo
                                    </div>
                                }
                            </div>
                        )}
                        {card.web_design_props.logo_position !== undefined && (
                            <div className={` ${card.web_design_props.logo_position[0]}-0 ${card.web_design_props.logo_position[1] === 'center' ? 'w-full' : `${card.web_design_props.logo_position[1]}-0`} absolute p-2`}>
                                {card.logo ?
                                    <img src={card.logo} className="w-6 mx-auto" alt="" />
                                    :
                                    <div className="w-12 border-dashed border h-6 flex--basic"
                                        style={{ color: `${card.default_color}`, borderColor: `${card.default_color}` }}
                                    >
                                        Logo
                                    </div>
                                }
                            </div>
                        )}
                    </>

                )}
            </div>
        </div>
    )
}

export default ECard;