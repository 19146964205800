import { Button, Table,Space } from 'antd';
import React from 'react';
import { EyeOutlined, QrcodeOutlined } from '@ant-design/icons';
import StatusIndicator from '../../../../../components/StatusIndicator/StatusIndicator';

const SingleMerchants = ({merchants, loading, onMerchantSelected = (merchant) => { }, onGenerateMerchantQr = (merchant) => { }}) => {

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 's_name',
        },
        {
            title: 'Location',
            dataIndex: 'address',
            key: 's_address',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 's_status',
            render: (text) => <StatusIndicator status={text} />
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 's_actions',
            render: (text) => (
                <Space>
                    <Button type="primary" shape="circle" icon={<EyeOutlined />} onClick={() => onMerchantSelected(text)}></Button>
                    <Button type="primary" shape="circle" icon={<QrcodeOutlined />} onClick={() => onGenerateMerchantQr(text)}></Button>

                </Space>
            )
        }
    ]

    return (
        <Table columns={columns} dataSource={merchants} loading={loading} />
    );
}


export default SingleMerchants;