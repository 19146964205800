import { Button, Col, Input, Row } from 'antd';
import React from 'react';
import colors from '../../../../configs/colors';

const AffiliateTopBar = ({onAddAffiliatePress, loading, onSendCodeClicked =() => { }, onVerifyCodeClicked =(_code) => { }}) => {
    const [code, setCode] = React.useState(null);
    return (
        <Row gutter={[16,16]}>
            <Col xs={24} sm={24} md={12}>
                <Button style={{background: colors.purple_blue, borderColor: colors.purple_blue, color: colors.white, borderRadius: '25px'}} onClick={onAddAffiliatePress}>ADD AFFILIATE</Button>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Row gutter={[16,16]}>
                    <Col xs={24} sm={24} md={6} style={{alignItems: 'center', justifyContent: 'center'}}>
                    <Button style={{background: colors.purple_blue, borderColor: colors.purple_blue, color: colors.white, borderRadius: '25px'}} loading={loading} onClick={onSendCodeClicked}>Send Code</Button>
                    <p style={{fontSize: '9px', lineHeight: '14px', fontWeight: 300}}>Click to send code to Affiliate</p>
                    </Col>
                    <Col xs={24} sm={24} md={10} style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                        <Input placeholder='Enter Code' onChange={(e) => setCode(e?.target?.value)} />
                        <p style={{fontSize: '9px', lineHeight: '14px', fontWeight: 300}}>Enter Code from  Affiliate information</p>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <Button type="primary" onClick={() =>onVerifyCodeClicked(code)} loading={loading}>Verify</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}


export default AffiliateTopBar;