import { Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import userService from '../../../../../services/user.service';


const MerchantTransactions = ({merchant, transactions = [], loading}) => {

    useEffect(() => {
        console.log('in effeect', transactions);
    }, [transactions]);
    const columns = [
        {
            title: 'Sale',
            dataIndex: '',
            key: 't_name',
            render: (text) => <span style={{fontWeight: 'bold'}}>{userService.fullname(text?.person)}</span>
        },
        {
            title: 'Location',
            dataIndex: 'address',
            key: 't_address',
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 't_created_at_date',
            render: (text) => dayjs(text).format("MMM. DD, YYYY")
        },
        {
            title: 'Time',
            dataIndex: 'created_at',
            key: 't_created_at_time',
            render: (text) => dayjs(text).format("h:m a")
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 't_amount',
            render: (text) => <span style={{fontWeight: 'bold'}}>{text}</span>
        },
        
    ]
    return (
        <Table dataSource={transactions} columns={columns} loading={loading} />
    );
}


export default MerchantTransactions;