import storageService from "./storage.service";
import tokenService from "./token.service";

const setLoginData = () => {
    return null;   
}

const isLogin = () => {
    const token = tokenService.getToken();
    if(!token) return false;

    return !tokenService.isExpired(token);
}

const getLoginUser = () => {
    return null;
}

const setCurrentMenu = (menu) => {
    localStorage.setItem('menu', menu);
}

const getUserResources = () => {
    const token = storageService.restoreToken();
    return token.resources || [];
}

const getUserResourcesCodes = () => {
    return getUserResources().map((resource) => resource.code);
}

const hasAny = (permissions = []) => {
    const resources = getUserResourcesCodes();
    const exists = resources.find((r) => permissions.includes(r));

    return exists  ? true: false;
}


const hasPermission = (permission) => {
    
    const exists = getUserResourcesCodes().find((r) => r === permission);

    return exists ? true: false;
}


const getCurrentMenu = () => {
    const menu = localStorage.getItem('menu');
    return menu ? menu: '/';
}


export default {
    setLoginData, isLogin, getLoginUser, setCurrentMenu, getCurrentMenu, getUserResources, getUserResourcesCodes,
    hasPermission, hasAny
}