import { Col, Row, Badge, Avatar, Menu, Modal, Spin } from "antd";
import {LogoutOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import AvatarImageCropper from 'react-avatar-image-cropper';
import React, { useEffect, useState } from "react";
import Logo from "../../../assets/logo.png";
import _Avatar from "../../../assets/avatar.png";

import "./AppHeader.less";
import { useAuth } from "../../../hooks/useAuth";
import AuthClient from "../../../api/auth/AuthClient";
import awsService from "../../../services/aws.service";
import UserClient from "../../../api/users/UserClient";
import colors from "../../../configs/colors";
const { SubMenu } = Menu;
const { confirm } = Modal;
const AppHeader = (props) => {

    const { logout } = useAuth();
    const [notificationCounter, setNotificationCounter] = useState(3);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [showProfileModal, setShowProfileModal] = useState(false);

    const  showConfirm = () => {
        confirm({
          title: 'Are you sure?',
          icon: <ExclamationCircleOutlined />,
          content: 'You are about to log out from Prime Dashboard.',
          okText: 'Yes, Logout',
          cancelText: 'No, Stay',
          onOk() {
            logout();
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

    const fetchCurrentUserDetail = async () => {

      setLoading(true);
      const response = await AuthClient.currentUser();
      setLoading(false);
      if(response.ok) {
        setUser(response.data.data.user || { });
      }

    }

      useEffect(() => {
        fetchCurrentUserDetail();
        setTimeout(() => setNotificationCounter(5), 3000);

        setTimeout(() => setNotificationCounter(9), 7000);

        setTimeout(() => setNotificationCounter(10), 10000);

      }, []);

  const handleSaveProfile =(file) => {
    setLoading(true);
    setShowProfileModal(false);
    awsService.sendToBucket(file).then((location) => {
  
      const payload = {profile_pic_url: location.Location};
      UserClient.updateCurrentUser(payload).then(() => {
        setLoading(false);
        const _user = {...user};
        _user.person.profile_pic_url = location.Location
        setUser(_user);
      }).catch(e => setLoading(false));
      
    }).catch(e => setLoading(false));
  }

  return (
    <div className="AppHeader">
      <Row>
        <Col span={6}>
          <img src={Logo} className="Logo" />
        </Col>

        <Col
          span={12}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <h4 className="AppAdminTitle">Admin Dashboard</h4>
        </Col>

        <Col
          span={6}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Row>
              {/* <Col xs={4} sm={4} md={6} style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
              <Badge count={notificationCounter} showZero>
            <BellOutlined style={{ color: "white", fontSize: "20px" }} />
          </Badge>
              </Col> */}

              <Col xs={20} sm={20} md={18} style={{display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '0 20px 0 25px'}}>
               {loading ? <Spin style={{color: colors.white}} /> : <Avatar  src={user.person?.profile_pic_url} size={45} style={{cursor: 'pointer'}} onClick={() => setShowProfileModal(true)} />}
              <span style={{color: 'white'}}>{user?.person?.first_name || '. . .'}</span>
              </Col>

              <Col xs={4} sm={4} md={6} style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
              <LogoutOutlined style={{ color: "white", fontSize: "20px" }} title="Logout" onClick={showConfirm} />
              </Col>
            </Row>  
    

          
        </Col>
      </Row>

      <Modal title="Profile Picture" visible={showProfileModal} footer={null} onCancel={()=>setShowProfileModal(false)} maskClosable={false} bodyStyle={{minHeight: '350px'}}>
        <div style={{ width: '250px', height: '250px', margin: 'auto', border: '1px solid black' }}>
            <AvatarImageCropper apply={handleSaveProfile} />
        </div>
      </Modal>
    </div>
  );
};

export default AppHeader;
