import apiClient from "../Client";

const fetchGeneralStats = () => apiClient.get('reports/general-stats');

const fetchTransactions = (params) => apiClient.get('reports/transactions', params);

const initiateReportGenerator = (payload) => apiClient.post('/reports/transactions/generate', payload);

const checkReportStatus = (report_id) => apiClient.get(`reports/${report_id}`);



export default {fetchGeneralStats, fetchTransactions, initiateReportGenerator, checkReportStatus}