import apiClient from "../Client";

const giveaways = (params) => apiClient.get('give-back-campaigns', params);

const addGiveAway = (payload) => apiClient.post('give-back-campaigns', payload);

const updateGiveAway = (id, payload) => apiClient.put('give-back-campaigns/' + id, payload);

const changeStatus = (id, payload) => apiClient.post(`give-back-campaigns/${id}/change-status`, payload);

export default { giveaways, addGiveAway, updateGiveAway, changeStatus}