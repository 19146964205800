const { default: apiClient } = require("../Client");

const fetchResources = () => apiClient.get('resources?include_resource_collection_tests');

const fetchEmployees = (params) => apiClient.get('users', params);

const addEmployees = (payload) => apiClient.post('users', payload);

const fetchEmployeeResources = (user_id) => apiClient.get(`users/${user_id}/resources`);

const updateEmployeeResources = (user_id, payload) => apiClient.post(`users/${user_id}/resources`, payload);

const updateEmployee = (user_id, payload) => apiClient.put(`users/${user_id}`, payload);

const updateCurrentUser = (payload) => apiClient.put(`users/details`, payload);



export default {fetchResources, fetchEmployees, addEmployees, fetchEmployeeResources, updateEmployeeResources, updateEmployee, updateCurrentUser}