import {
  Button,
  Card,
  Col,
  Row,
  Tabs,
  Select,
  Table,
  Modal,
  Form,
  Input,
  Upload,
  Space,
  Avatar,
  Divider,
} from "antd";
import React, { useState } from "react";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import colors from "../../../../configs/colors";
import FilterPane from "../../../../components/Filter/FilterPane";
import userService from "../../../../services/user.service";
import UserClient from "../../../../api/users/UserClient";
import notify from "../../../../services/notification.service";
import networkService from "../../../../services/network.service";
import Uploader from "../../../../components/uploader/uploader";

const { TabPane } = Tabs;
const { Option } = Select;

const AccountEmployees = ({
  employees = [],
  resources = [],
  loading = false,
  onSearchEmployees = (params) => {},
}) => {
  const [isAddEmployeeModalVisible, setIsAddEmployeeModalVisible] = useState(
    false
  );
  const [employee, setEmployee] = useState(null);
  const [employeeResources, setEmployeeResources] = useState([]);
  const [employeeResourceLoading, setEmployeeResourceLoading] = useState(false);
  const [employeeLoading, setEmployeeLoading] = useState(false);
  const [profileUrl, setProfileUrl] = useState(null);
  const [clearUploader, setClearUploader] = useState(false);
  const [form] = Form.useForm();
  const showAddEmployeeModal = () => {
    setEmployee(null);
    setIsAddEmployeeModalVisible(true);
  };

  const hanleAddEmployeeModalOk = () => {
    setIsAddEmployeeModalVisible(false);
    setClearUploader(!clearUploader);
  };

  const hanleAddEmployeeModalCancel = () => {
    setIsAddEmployeeModalVisible(false);
    form.resetFields();
    setClearUploader(!clearUploader);
  };

  const onEmployeeSelected = (_employee) => {
    setEmployee(_employee);
    setEmployeeResources([]);
    if (_employee) {
      onFetchEmployeeResources(_employee.id);
    }
  };

  const onEditEmployee = (_employee) => {
    const _form = {
      first_name: _employee.person.first_name,
      surname: _employee.person.surname,
      gender: _employee.person.gender_id,
      telephone: _employee.phone_number,
      email: _employee.email,
      username: _employee.username,
    };
    form.setFieldsValue(_form);
    onEmployeeSelected(_employee);
    setIsAddEmployeeModalVisible(true);
  };

  const onFetchEmployeeResources = async (id) => {
    setEmployeeResourceLoading(true);
    const response = await UserClient.fetchEmployeeResources(id);
    setEmployeeResourceLoading(false);
    if (response.ok) {
      const codes = response.data.data.resources || [];
      setEmployeeResources(codes.map((c) => c.code));
    }
  };

  const handleResetForm = () => {
    setEmployee(null);
    form.resetFields();
    setClearUploader(!clearUploader);
  };

  const onChangeUserPermission = async () => {
    if (!employee) {
      notify("warning", "Seletc Employee First!");
      return;
    }

    setEmployeeResourceLoading(true);
    const response = await UserClient.updateEmployeeResources(employee.id, {
      resources: employeeResources,
    });
    setEmployeeResourceLoading(false);

    if (response.ok) {
      notify("success", "Permissions", "Employee Permissions updated.");
      return;
    }

    networkService.handleFailed(response);
  };

  const handlePermissionInputChange = (values) => {
    setEmployeeResources(values);
  };

  const handleOnAddEmployee = async (values) => {
    setEmployeeLoading(true);
    if (profileUrl) {
      values.profile_pic_url = profileUrl;
    }
    const response = employee
      ? await UserClient.updateEmployee(employee.id, values)
      : await UserClient.addEmployees({
          ...values,
          confirmed_password: values.password,
        });
    setEmployeeLoading(false);
    if (response.ok) {
      hanleAddEmployeeModalCancel();
      const message = employee ? "Employee Updated." : "Employee Saved.";
      notify("success", message);
      onSearchEmployees();
      return;
    }

    networkService.handleFailed(response);
  };

  const employeeColumn = [
    {
      title: "Profile",
      key: "profile",
      render: (_emp) => (
        <Avatar src={_emp?.person?.profile_pic_url} size={45} />
      ),
    },
    {
      title: "Name",
      key: "name",
      render: (_emp) => userService.fullname(_emp?.person),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
        title: "TIN",
        key: "tin",
        render: (_emp) => _emp?.person?.tin_number,
      },
    {
      title: "Action",
      key: "action",
      render: (_emp) => (
        <Space>
          <EyeOutlined onClick={() => onEmployeeSelected(_emp)} />{" "}
          <EditOutlined onClick={() => onEditEmployee(_emp)} />
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={[16, 16]}>
      <Col xs={24}>
        <Button
          style={{
            background: colors.purple_blue,
            borderColor: colors.purple_blue,
            color: colors.white,
            borderRadius: "25px",
          }}
          onClick={showAddEmployeeModal}
        >
          ADD EMPLOYEE
        </Button>
      </Col>

      <Col xs={24} sm={24} md={12}>
        <Card>
          <Tabs>
            <TabPane tab="EMPLOYEES" key="employees_1">
              <Row gutter={[16, 16]}>
                <Col xs={12} sm={24} md={24}>
                  <FilterPane showStatus={false} onSearch={onSearchEmployees} />
                </Col>

                <Col xs={24} className="mb-4">
                  <Table
                    dataSource={employees}
                    columns={employeeColumn}
                    loading={loading}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Card>
      </Col>

      <Col xs={24} sm={24} md={12}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24}>
            <Card>
              {!employee && (
                <span style={{ color: colors.gray }}>
                  No Employee Selected.
                </span>
              )}

              {employee ? (
                <>
                  <Row gutter={[16, 16]}>
                    {employee.image && (
                      <Col sm={24}>
                        <Avatar src={employee?.image} />
                      </Col>
                    )}
                    <Col sm={24} md={12}>
                      <span style={{ fontWeight: "bold" }}>Name: </span>
                      <span style={{ color: colors.gray }}>
                        {userService.fullname(employee.person)}
                      </span>
                    </Col>

                    <Col sm={24} md={12}>
                      <span style={{ fontWeight: "bold" }}>Email: </span>
                      <span style={{ color: colors.gray }}>
                        {employee.email}
                      </span>
                    </Col>

                    <Col sm={24} md={12}>
                      <span style={{ fontWeight: "bold" }}>Username: </span>
                      <span style={{ color: colors.gray }}>
                        {employee.username}
                      </span>
                    </Col>

                    <Col sm={24} md={12}>
                      <span style={{ fontWeight: "bold" }}>Telephone: </span>
                      <span style={{ color: colors.gray }}>
                        {employee.telephone}
                      </span>
                    </Col>

                    <Col sm={24} md={24}>
                      <Divider />
                    </Col>

                    <Col sm={24} md={24}>
                      <span style={{ fontWeight: "bold" }}>Permissions</span>
                    </Col>

                    <Col sm={24} md={24}>
                      <Select
                        placeholder="Change Permission"
                        mode="multiple"
                        value={employeeResources}
                        onChange={handlePermissionInputChange}
                        style={{ width: "100%" }}
                        loading={employeeResourceLoading}
                      >
                        {resources.map((resource) => (
                          <Option
                            key={resource.id + "_resources"}
                            value={resource.code}
                          >
                            {resource.name}
                          </Option>
                        ))}
                      </Select>
                    </Col>

                    <Col sm={24} md={24} style={{ textAlign: "right" }}>
                      <Button
                        type="primary"
                        onClick={onChangeUserPermission}
                        loading={employeeResourceLoading}
                      >
                        Change Permissions
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : null}
            </Card>
          </Col>
        </Row>
      </Col>

      <Modal
        title={
          employee
            ? "Update " + userService.fullname(employee.person)
            : "Add Employee"
        }
        footer={null}
        visible={isAddEmployeeModalVisible}
        onOk={hanleAddEmployeeModalOk}
        onCancel={hanleAddEmployeeModalCancel}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{ first_name: "" }}
          onFinish={handleOnAddEmployee}
        >
          <Row>
            <Col xs={24} sm={24} md={8}>
              <Uploader
                onClear={clearUploader}
                uploadImage={(url) => setProfileUrl(url)}
              />
            </Col>

            <Col xs={24} sm={24} md={16}>
              <Form.Item
                hasFeedback
                name="first_name"
                rules={[{ required: true, message: "Please Enter First Name" }]}
              >
                <Input placeholder="First Name" />
              </Form.Item>

              <Form.Item
                hasFeedback
                name="surname"
                rules={[{ required: true, message: "Please Enter Surname" }]}
              >
                <Input placeholder="Surname" />
              </Form.Item>

              <Form.Item
                hasFeedback
                name="gender"
                rules={[{ required: true, message: "Please Select Gender" }]}
              >
                <Select placeholder="Select Gender">
                  <Option value="male">Male</Option>
                  <Option value="female">Female</Option>
                </Select>
              </Form.Item>

              <Form.Item
                hasFeedback
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please Enter Email",
                  },
                ]}
                name="email"
              >
                <Input placeholder="Email" type="email" />
              </Form.Item>

              <Form.Item hasFeedback name="tin_number" >
                            <Input placeholder="TIN" />
                </Form.Item>

              <Form.Item name="telephone">
                <Input placeholder="Phone Number" />
              </Form.Item>

              <Form.Item name="username">
                <Input placeholder="Username" />
              </Form.Item>

              {!employee ? (
                <Form.Item
                  name="password"
                //   rules={[
                //     {
                //       required: !employee ? true : false,
                //       message: "Please Enter Password",
                //     },
                //     { min: 6, type: "string", message: "Password too short" },
                //   ]}
                >
                  <Input.Password placeholder="Temporary Password" />
                </Form.Item>
              ) : null}

              {!employee ? (
                <Form.Item
                  hasFeedback
                  name="resources"
                //   rules={[
                //     {
                //       required: !employee ? true : false,
                //       message: "Please Select Permission",
                //     },
                //   ]}
                >
                  <Select placeholder="Select Permission" mode="multiple">
                    {resources.map((resource) => (
                      <Option
                        key={resource.id + "_resources"}
                        value={resource.code}
                      >
                        {resource.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : null}

              <Form.Item>
                <Space>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={employeeLoading}
                  >
                    Submit
                  </Button>
                  <Button type="default" onClick={handleResetForm}>
                    Reset
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Row>
  );
};

export default AccountEmployees;
