import './uploader.css';
import React, { useEffect, useState } from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import awsService from '../../services/aws.service';


const UniversalUploader = ({ 
    children,
    onFileUploaded=(_f)=>{console.log('image location', _f)}, 
    classStyle,
    onClear 
}) => {
    const [url, setUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    const props = {
        name: 'file',
        beforeUpload: file => {
            setLoading(true);
            awsService.sendToBucket(file)
            .then((response) => {
                setLoading(false);
                setUrl(url);
                onFileUploaded(response.Location)
            })
            .catch(err => {
                console.log('Error:', err);
                setLoading(false);
            })       
            

            return false;
        },        
    };

    useEffect(() => {
        setUrl(null);
    }, [onClear]);


    return (
        <>

            <Upload {...props} style={classStyle}>
                <Button icon={loading ? <LoadingOutlined /> : <UploadOutlined />}>Click to Upload</Button>
            </Upload>
        </>
    );
};

export default UniversalUploader;
