import React from 'react';
import colors from '../../configs/colors';

const PlatformIndicator = ({background, color = colors.white, fontSize= '11px', width = '100%', radius = '10px', platform}) => {
    return (
        <div className='PlatformIndicator' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px', height: '40px', color, 
        background, borderTopRightRadius: radius, borderBottomRightRadius: radius, width}}>
            <span style={{fontSize}}>{platform}</span>
        </div>
    );
}


export default PlatformIndicator;