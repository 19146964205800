import React from 'react';
import ReactPlayer from 'react-player'

const VideoPlayer = ({url}) => {
    return (
        <ReactPlayer controls={true} url={url} />
    );
}


export default VideoPlayer;