import { Col, Row, Modal, Select, Button, Input, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import AffiliateClient from '../../../api/affiliate/AffiliateClient';
import StatusIndicatorForm from '../../../components/StatusIndicator/StatusIndicatorForm';
import networkService from '../../../services/network.service';
import notify from '../../../services/notification.service';
import './Affiliate.less'
import AffliliateDetail from './AffiliateDetail/AffliliateDetail';
import AffiliateTabs from './AffiliateTabs/AffiliateTabs';
import AffiliateTopBar from './AffiliateTopBar/AffiliateTopBar';
import AffiliateTransactionReportTabs from './AffiliateTransactionReportTabs/AffiliateTransactionReportTabs';

const { Option } = Select;
const { TextArea } = Input;

const Affiliate = (props) => {
    
    const [affiliate, setAffiliate] = useState();
    const [showAffiliateFormModal, setAffiliateFormModal] = useState(false);
    const [showChangeStatus, setChangeStatus] = useState(false);
    const [affiliates, setAffiliates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [merchantLoading, setMerchantLoading] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [statusLoading, setStatusLoading] = useState(false);

    const onAffiliateSelected = (_affiliate) => {
        fetchAffiliate(_affiliate?.id);
        setAffiliate(_affiliate);
        fetchAffiliateMerchants(_affiliate?.id);
        fetchAffiliateStatusChanges(_affiliate?.id);
    }

    const onToggleAffiliateModal = () => {
        setAffiliateFormModal(() => !showAffiliateFormModal);
    }

    const onToggleChangeStatusModal = () => {
        setChangeStatus(() => !showChangeStatus);
    }

    const handleChangeStatusForm = async (values) => {
        setLoading(true);
        const response = await AffiliateClient.changeStatus(affiliate?.id, values)
        setLoading(false);

        if(response.ok) {
            notify('success', 'Affiliate', 'Affiliate Status Changed Successfully');
            const ua = affiliates.map((_a) => {
                if(_a.id == response?.data?.data?.affiliate.id) return response?.data?.data?.affiliate;
                return _a;

            });

            setAffiliates(ua);
            setAffiliate( response?.data?.data?.affiliate);
            fetchAffiliateStatusChanges(affiliate?.id);
           
            onToggleChangeStatusModal();

        } else {
            if(response.problem == 'NETWORK_ERROR') {
                notify('error', 'Network Error', 'Please Check Internet');
            } else {
                notify('error', 'Error', response.data?.error_msg);
            }
        }
    }

    const fetchAffiliate = async (id) => {
        const response = await AffiliateClient.fetchAffiliate(id);

        if(response.ok) {
            setAffiliate(response.data.data?.affiliate);
        }
    }

    const handleChangeStatusModalClose = () => {
        onToggleChangeStatusModal();
    }

    const onHandleAffiliateAdded = () => {
        fetchAffiliates();
    }
    const fetchAffiliates = async (params = { limit: 30}) => {
        setLoading(true);
        const response = await AffiliateClient.fetchAffiliates(params);
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                setAffiliates(response.data.data.affiliates);
            }
        }
    }

    const fetchAffiliateMerchants = async (id) => {
        if(!id) return ;
        setMerchantLoading(true);
        const response = await AffiliateClient.fetchAffiliateMerchants(id);
        setMerchants([]);
        setMerchantLoading(false);
        if(response.ok) {
            setMerchants(response.data?.data?.merchants || []);
        }

    }

    const fetchAffiliateStatusChanges = async (id) => {
        if(!id) return ;
        setStatusLoading(true);
        const response = await AffiliateClient.fetchAffiliateStatusChanges(id);
        setStatuses([]);
        setStatusLoading(false);
        if(response.ok) {
            setStatuses(response.data?.data?.status_changes || []);
        }

    }

    const handleSendCode = async() => {
        if (!affiliate) {
            notify('warning', 'Code', 'Please Select Affiliate First!');
            return ;
        }
        setLoading(true);
        const response = await AffiliateClient.sendAffiliateCode(affiliate.id, {affiliate_id: affiliate.id});
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                notify('success', 'Code', 'Code Sent Successfully');
            }

            return ;
        }

        networkService.handleFailed(response);

    }

    const handleVerifyCode = async(code) => {
        if (!affiliate) {
            notify('warning', 'Code', 'Please Select Affiliate First!');
            return ;
        }

        if (!code) {
            notify('warning', 'Code', 'Please Enter Code');
            return ;
        }
        setLoading(true);
        const response = await AffiliateClient.verifyCode(affiliate.id, {code});
        setLoading(false);
        if(response.ok) {
            if(response.data && response.data.data) {
                notify('success', 'Code', 'Code Verified.');
            }

            return ;
        }

        networkService.handleFailed(response);

    }


    useEffect(() => {
        fetchAffiliates();
    }, []);

    
    return (
        <div className='Affiliate'>
            <Row gutter={[16,16]}>
                <Col span={24}>
                    <AffiliateTopBar loading={loading} onVerifyCodeClicked={handleVerifyCode} onAddAffiliatePress={onToggleAffiliateModal} onSendCodeClicked={handleSendCode} />
                </Col>
            </Row>

            <Row gutter={[16,16]}>
                <Col xs={24} sm={24} md={12}>
                    <AffiliateTabs affiliates={affiliates} onAffiliateSelected={onAffiliateSelected} onAffiliateAdded={onHandleAffiliateAdded} onToggleAffiliateModal={onToggleAffiliateModal}
                    onShowAffiliateModal={showAffiliateFormModal} onSearch={(values) => fetchAffiliates(values)} searchLoading={loading} />
                </Col>

                <Col xs={24} sm={24} md={12}>
                    <Row gutter={[16,16]}>

                        <Col xs={24} sm={24}>
                            <AffliliateDetail affiliate={affiliate} onChangeStatusClicked={onToggleChangeStatusModal} />
                        </Col>


                        <Col xs={24} sm={24}>
                            <AffiliateTransactionReportTabs 
                                merchants={merchants} 
                                merchantLoading={merchantLoading} 
                                statusLoading={statusLoading}
                                statuses={statuses}
                                affiliate={affiliate} />
                        </Col>

                    </Row>
                    
                </Col>
            </Row>


            <StatusIndicatorForm status={affiliate?.status} showChangeStatus={showChangeStatus} loading= {loading} handleChangeStatusForm={handleChangeStatusForm} handleChangeStatusModalClose={handleChangeStatusModalClose} />
        </div>
    );
}


export default Affiliate;