import apiClient from "../Client";

const fetchTraffic = (params) => apiClient.get('dashboard/general-stats', params);

const fetchMonthlyOverview = (params) => apiClient.get('dashboard/monthly-totals', params);

const usersGeneralStats = () => apiClient.get('dashboard/users/general-stats');

const newUsers = () => apiClient.get('dashboard/users', {limit: 30});

const topMerchants = () => apiClient.get('dashboard/merchants/top');

const fetchMerchants = () => apiClient.get('dashboard/merchants/new');

export default { fetchTraffic, fetchMonthlyOverview, usersGeneralStats, newUsers, topMerchants, fetchMerchants};