import { create } from 'apisauce';
import urls from '../configs/urls';
import tokenService from '../services/token.service';

const apiClient = create({
    baseURL: `${urls.base}`,
});

apiClient.addRequestTransform((request) => {
    const token = tokenService.getToken();
    if(token) {
        request.headers['Authorization'] = 'Bearer ' + token;
    }

    request.headers['Da-Secret'] = 'pCweb'
});

apiClient.addResponseTransform((response) => {
    if(response.status === 401) {       
        // notify('info', 'Session Expired', 'Please logout and login Again');
    }
})

export default apiClient;