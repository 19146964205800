import './uploader.css';
import React, { useEffect, useState } from 'react';
import { Card, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import awsService from '../../services/aws.service';


const Uploader = ({ 
    children,
    showButton=true,
    disabled = false, 
    uploadImage=(_f)=>{console.log('image location', _f)}, 
    classStyle,
    uploadText="Drag and Drop file",
    bg="", 
    isThumbnail = false,
    thumbBack,
    onClear 
}) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            callback(reader.result)
        });
        reader.readAsDataURL(img);
    }; 

    const props = {
        name: 'file',
        listType: "picture-card",
        multiple: false,
        action: null,
        showUploadList: false,
        beforeUpload: file => {
            setLoading(true);
            getBase64(file, (imageUrl) => {
                file.preview = imageUrl;
                awsService.sendToBucket(file)
                .then((response) => {
                    setImageUrl(imageUrl);
                    setLoading(false);
                    uploadImage(response.Location)
                })
                .catch(err => {
                    setLoading(false);
                    console.log('Error:', err);
                })                 
            });       
            

            return false;
        },        
        onPreview: file => {
            if (file.preview) return true
            else return false;         
        } ,
        
        className: "avatar-uploader"       
    };

    useEffect(() => {
        setImageUrl(null);
        setLoading(false);
    }, [onClear]);

    useEffect(() => {
        setImageUrl(thumbBack);
    }, [thumbBack]);

    const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );
    
    return (
        <>

        <Upload
                {...props}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        </>
    );
};

export default Uploader;
