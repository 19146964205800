import aws from "../configs/aws";
import utilsService from "./utils.service";
const S3 = require('aws-sdk/clients/s3');

const sendToBucket = (file) => {

    var BucketName = aws.AWS_BUCKET;
    var accessKeyId = aws.ACCESS_KEY_ID;
    var SecretAccessKey = aws.SECRET_ACCESS_KEY;

    var s3 = new S3({
      apiVersion: '2006-03-01',
      region: 'us-east-2',
      accessKeyId: accessKeyId,
      secretAccessKey: SecretAccessKey,
      params: {Bucket: BucketName}
    })
    var albumFileKey = encodeURIComponent('prime-images') + '/'
    var fileKey = albumFileKey + utilsService.randomString(5) + '_' + file?.name

    var params = {Bucket: BucketName, Key: fileKey, Body: file}
    return new Promise((resolve, reject) => {
      s3.upload(params, function (err, data) {
        if (err) {
          reject(err)
          return
        }
        resolve(data);
      })
    })    
}

export default {sendToBucket}