const { default: sikaClient } = require("../SikaClient");
const { default: apiClient } = require("../Client");

const fetchMerchants = (params) => apiClient.get('merchants', params);

const fetchMerchant = (id) => apiClient.get(`merchants/${id}`);

const fetchMerchantCards = (id, params) => apiClient.get(`merchants/${id}/cards`, params ||{limit: 10});

const fetchMerchantTransactions = (id, params) => apiClient.get(`merchants/${id}/transactions`, params ||{limit: 10});

const changeStatus = (merchant_id, payload) => apiClient.post(`merchants/${merchant_id}/change-status`, payload);

const sendMerchantCode = (merchant_id, payload) => apiClient.post(`merchants/${merchant_id}/send-code`, payload);

const changeTINStatus = (merchant_id, payload) => apiClient.post(`merchants/${merchant_id}/change-tin-verification-status`, payload);

const resendVerificationCode = (payload) => sikaClient.post(`users/resend-merchant-verification-code`, payload);

const verifyCode = (merchant_id, params) => apiClient.get(`merchants/${merchant_id}/verify-code`, params);

const verifyMerchantTIN = (id) => apiClient.post(`merchants/${id}/verify-tin?include_message_tests`);

const updateMerchant = (merchant_id, payload) => apiClient.put(`merchants/${merchant_id}`, payload);

export default {
    fetchMerchants, fetchMerchantCards,fetchMerchantTransactions, changeStatus, sendMerchantCode, verifyCode,
    fetchMerchant, resendVerificationCode, changeTINStatus,verifyMerchantTIN, updateMerchant
}

