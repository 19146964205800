import { Button, Card, Col, Row, Space } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import colors from "../../configs/colors";

const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

const NewMerchantAnalytics = ({overviewData, onSearchBy = (query) => { }, loading = false}) => {
    const [searchBy, setSearchBy] = useState('merchant');
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
          {
            label: '',
            data: [],
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
          },
        ],
      })

    const onSearchByClick = (by) => {
        setSearchBy(() => by);
        onSearchBy(by);
    }

    const transformData = () => {
        const type = searchBy === 'user' ? 'Users' : 'Merchants';
        const datasets = overviewData.totals.map((d) => d.total);
        const labels = overviewData.totals.map((d) => d.month);
        const label = `# of ${type}`;
        setChartData({labels, datasets: [{label, data: datasets, fill: false, backgroundColor: 'rgb(255, 99, 132)', borderColor: 'rgba(255, 99, 132, 0.2)'}]});
    }


    useEffect(() => {
      transformData();
    }, [overviewData]);

  return (
    <Card bordered={false} title={
      <Row>
      <Col xs={24} sm={24} style={{display: 'flex', justifyContent: 'space-between'}}>
      <div style={{ display: "flex", flexDirection: "column" }}>
      <span
        style={{ fontSize: "10px", lineHeight: "15px", color: colors.gray }}
      >
        OVERVIEW
      </span>
      <span
        style={{
          color: colors.primary,
          fontWeight: "bold",
          fontSize: "13px",
          lineHeight: "19px",
        }}
      >
        Monthly Overview
      </span>
    </div>

    <Space>
        <Button type={searchBy == 'merchant' ? 'primary': 'ghost'} onClick={() => onSearchByClick('merchant')} loading={loading}>Merchants</Button>
        <Button type={searchBy == 'user' ? 'primary': 'ghost'} onClick={() => onSearchByClick('user')} loading={loading}>Users</Button>
    </Space>
      </Col>
    </Row>  
    }>


        <Row gutter={[16,16]}>
            <Col xs={24}>
                <Line data={chartData} options={options}  />
            </Col>
        </Row>
    </Card>
  );
};

export default NewMerchantAnalytics;
