import { Col, Button, DatePicker, Space, Row, Card } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import Timer from "../../../containers/Timer/Timer";
import { useAuth } from "../../../hooks/useAuth";
import DashboardAsset from "../../../assets/dashboard-asset.png";
import DataTransformer from "../../../services/analytics.service";
import TrafficAnalytic from "../../../components/Analytics/traffic-analytics/TrafficAnalytic";
import colors from "../../../configs/colors";
import NewMerchantAnalytics from "../../../containers/NewMechants/NewMerchantAnalytics";
import CurrentUsers from "../../../components/current-users/CurrentUsers";
import TopMerchantLocation from "../../../components/top-mechants/top-merchant-location/TopMerchantLocation";
import TopMerchantSale from "../../../components/top-mechants/top-merchants-sale/TopMerchantSale";
import dashboardClient from "../../../api/dashboard/DashboardClient";
import tokenService from "../../../services/token.service";

const preMonthly = {
  "totals": [
      {
        "month": "jan",
        "total": 0
      },
      {
        "month": "feb",
        "total": 0
      },
      {
        "month": "mar",
        "total": 0
      },
      {
        "month": "apr",
        "total": 0
      },
      {
        "month": "may",
        "total": 0
      },
      {
        "month": "jun",
        "total": 0
      },
      {
        "month": "jul",
        "total": 0
      },
      {
        "month": "aug",
        "total": 0
      },
      {
        "month": "sep",
        "total": 0
      },
      {
        "month": "oct",
        "total": 0
      },
      {
        "month": "nov",
        "total": 0
      },
      {
        "month": "dec",
        "total": 0
      }
    ]
  };

  const preUserStats = {
    new_users: {
      count: 0,
      comparison_percentage: 0
    },
    users: {
      count: 3,
      ios_count: 3,
      android_count: 3
    }
  }

const Dashboard = (props) => {
  const [traffic, setTraffic] = useState([]);
  const [searchDate, setSearchDate] = useState();
  const { setLogin } = useAuth();
  const [trafficLoading, setTrafficLoading] = useState(false);
  const [overviewLoading, setOverviewLoading] = useState(false);
  const [monthlyOverviewData, setMonthlyOverviewData] = useState(preMonthly);
  const [showMonthFilter, setMonthFilter] = useState(true);
  const [userStats, setUserStats] = useState(preUserStats);
  const [newUsersLoading, setNewUsersLoading] = useState(false);
  const [newUsers, setNewUsers] = useState([]);
  const [topMerchants, setTopMerchants] = useState([]);
  const [topMerchantLoading, setTopMerchantLoading] = useState(false);
  const [merchantData, setMerchantData] = useState({merchants: [], total_records: 0});
  const [merchantLoading, setMerchantLoading] = useState([]);
  const traffic_key = "traffic_key_";

  const handleLogout = () => {
    setLogin(false);
  };

  const onMonthChange = (date, dateString) => {
    setSearchDate(dateString);  
  };

  const fetchTraffic = (params) => {
    if(!params && searchDate) {
      params = { }
      params.year = dayjs(searchDate).format('YYYY');
      params.month = dayjs(searchDate).format('MMM').toLowerCase();
    }
    setTrafficLoading(true);
    dashboardClient.fetchTraffic(params).then((res) => {
      setTrafficLoading(false);
      if(res.ok) {      
        const data = DataTransformer.transformTraffic(res.data.data, searchDate);
        setTraffic(() => data);
      }
    }).catch(e =>setTrafficLoading(false));
  }

  const fetchMontlyOverview = (query = 'merchant') => {
    setOverviewLoading(true);
    dashboardClient.fetchMonthlyOverview({for: query}).then((res) => {
      setOverviewLoading(false)
      if(res.ok) {
        setMonthlyOverviewData(res.data.data);
      } else {
        setMonthlyOverviewData(preMonthly);
      }
    }).catch(e => setOverviewLoading(false));
  }

  const fetchNewUsers = () => {
    setNewUsersLoading(true);
    dashboardClient.newUsers().then((res) => {
      setNewUsersLoading(false)
      if(res.ok) {
        setNewUsers(res.data?.data?.users);
      } 
    }).catch(e => setNewUsersLoading(false));
  }

  const fetchUserStats = (query) => {

    dashboardClient.usersGeneralStats().then((res) => {
      if(res.ok) {
        setUserStats(res.data.data);
      }

    });
  }

  const fetchTopMerhcants = () => {
    setTopMerchantLoading(true);
    dashboardClient.topMerchants().then((res) => {
      setTopMerchantLoading(false)
      if(res.ok) {
        setTopMerchants(res.data?.data?.merchants);
      } 
    }).catch(e => setTopMerchantLoading(false));
  }

  const fetchMerchants = () => {
    setMerchantLoading(true);
    dashboardClient.fetchMerchants().then((res) => {
      setMerchantLoading(false)
      if(res.ok) {
        setMerchantData(res.data?.data);
      } 
    }).catch(e => setMerchantLoading(false));
  }

  useEffect(() => {
    fetchTraffic();
    fetchMontlyOverview();
    fetchUserStats();
    fetchNewUsers();
    fetchTopMerhcants();
    fetchMerchants();
  }, []);
  return (
    <div className="Dashboard">
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Col xs={24} sm={24} md={12}>
          <Space>
            <img
              src={DashboardAsset}
              className="w-6 h-full cursor-pointer"
              onClick={() =>setMonthFilter(() => !showMonthFilter)}
            />
            {showMonthFilter && <><DatePicker onChange={onMonthChange} picker="month" />
            <Button type="primary" onClick={() => fetchTraffic()} loading={trafficLoading}>Display</Button></>}
          </Space>
        </Col>

        <Col xs={24} sm={24} md={12}>
          <Timer />
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        {traffic.map((t, i) => (
          <Col key={traffic_key + i} xs={24} sm={24} md={6}>
            <TrafficAnalytic
              title={t.title}
              amount={t.count}
              percent={t.comparison_percentage}
              description={t.description}
            />
          </Col>
        ))}
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12}>
            <NewMerchantAnalytics overviewData={monthlyOverviewData} loading={overviewLoading} onSearchBy={(q) => fetchMontlyOverview(q)} />
        </Col>

        <Col xs={24} sm={24} md={12}>
          <CurrentUsers loading={newUsersLoading} users={newUsers} userStats={userStats} />
        </Col>

        {/* <Col xs={24} sm={24} md={6}>
          <Card bordered={false} title="E-FUND"></Card>
        </Col> */}
      </Row>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={12}>
        <TopMerchantLocation data={merchantData} loading={merchantLoading} />
         
        </Col>

        <Col xs={24} sm={24} md={12}>
        <TopMerchantSale merchants={topMerchants} loading={topMerchantLoading} />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
