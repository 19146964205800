const TOKEN_KEY = "token";

const USER_KEY = 'user';

const storeToken = (token) => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
    return true;
}

const storeUser = (user) => {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    return true;
}

const restoreToken = () => {
    const token = localStorage.getItem(TOKEN_KEY);

    return token ? JSON.parse(token): null;
}

const restoreUser = () => {
    const user = localStorage.getItem(USER_KEY);

    return user ? JSON.parse(user): { };
}

const removeUser = () => {
    localStorage.removeItem(USER_KEY);
    return true;
}

const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
    return true;
}

export default {
    storeToken, restoreToken, removeToken, storeUser, restoreUser, removeUser
}